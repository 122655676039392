import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ActivatedRoute } from '@angular/router'
import { ApiService } from 'src/app/services/api.service';
import { TituloService } from 'src/app/services/titulo.service';
import { tiposCabeceraReport } from 'src/app/constants/comprasChapa.constant';
import { estadoCompra } from 'src/app/constants/comprasChapa.constant';

@Component({
  selector: 'app-report-compra-chapa',
  standalone: false,
  templateUrl: './report-compra-chapa.component.html',
  styleUrl: './report-compra-chapa.component.css'
})
export class ReportCompraChapaComponent implements OnInit {
  public compra: any = {};
  public compraCompleta: any = {};
  public tiposCabecera = tiposCabeceraReport;

  public contadorLineas = 0;
 
  public paginas: any[] = [];
  public freeSpaceCuentaLienas: number = 0;
  public templateHeight: number = 0;
  public lineHeight: number = 31.83;
  public linesPerPage: number = 39;
  public totalNumPeces: number = 0;
  public totalPesAlta: number = 0;
  public totalNumPecesTotal: number = 0;
  public totalPesAltaTotal: number = 0;

  public nPagina = 0;

  public idIdioma = 2;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private tituloService: TituloService,
  ) { }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.activatedRoute.queryParams.subscribe(data => {
      this.compra = data;
      console.log("DATOS DE LA COMPRA: >>>>>>>>>>", this.compra);

    this.apiService.obtenerCompraChapaDetalle((this.compra['idCompra'])).subscribe(data => {
      this.compraCompleta = data.response;
      for(let i in this.compraCompleta) {
        this.totalNumPeces += this.compraCompleta[i].NumPeces ? this.compraCompleta[i].NumPeces : 0;
        this.totalPesAlta += this.compraCompleta[i].PesAlta ? this.compraCompleta[i].PesAlta : 0;
        this.totalNumPecesTotal += this.compraCompleta[i].TotalPecesEntregats ? this.compraCompleta[i].TotalPecesEntregats : 0;
        this.totalPesAltaTotal += this.compraCompleta[i].TotalPesLliurat ? this.compraCompleta[i].TotalPesLliurat : 0;
      }
      this.cuentaLineas();
      console.log( "DATA PARA IMPRIMIR: >>>>>>>>>>", this.compraCompleta);
    })
    })
  }

  private stringToHTML(str: any) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, 'text/html');
    return doc.body;
  };

  public convertirAPdf() {
    setTimeout(() => {
      this.downloadPDF();
    }, 200);
  }

  downloadPDF() {
    window.scrollTo(0, 0);
    const doc = new jsPDF('p', 'pt', 'a4');
    Promise.all(Array.from(document.getElementsByClassName('pagina')).map((pagina) => {
      return html2canvas(pagina as HTMLElement, { scale: 2 })
    })).then((canvasList) => {
      canvasList.forEach((canvas, idx) => {
        const img = canvas.toDataURL('image/SVG');
        const bufferX = 15;
        const bufferY = 15;
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        idx !== 0 && doc.addPage();
        doc.addImage(img, 'SVG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      })
      doc.save(`compra_${this.compra['idTipo']}_${this.compra['dataCompra']}.pdf`);
    })
  }

  public getHeightTemplate() {
    if (this.templateHeight == 0) {
      setTimeout(() => {
        let template = document.getElementById('heightTemplate');
        if (template) {
          this.templateHeight = template.getBoundingClientRect().height;
          console.log("templateHeight: ", this.templateHeight);
        }
      }, 1000);
    }
    return false;
  }
  private cuentaLineas() {
    let lineasPagina = 0;
    let pagina: any[] = [];
    let cantidadCompras = this.compraCompleta.length;
    let contadorCompras = 0;

    this.compraCompleta.forEach((compra:any, index:number) => {
        contadorCompras++;
        let lineasCompras = 2;
        lineasPagina += lineasCompras;

        if (lineasPagina < 105) {
            pagina.push(compra);
            lineasCompras = 0;
            if (contadorCompras == cantidadCompras) {
                ++this.nPagina;
                let element = {
                    npagina: this.nPagina,
                    ultimaPagina: false,
                    compras: pagina
                };
                this.paginas.push(element);
            }
        } else {
            ++this.nPagina;
            let element = {
                npagina: this.nPagina,
                ultimaPagina: false,
                compras: pagina
            };
            this.paginas.push(element);

            lineasPagina = lineasCompras;
            pagina = [compra];
        }
    });

    lineasPagina += 4;

    console.log("PAGINAS: ", this.paginas);
}

  public colorRow(rowEstado: string): any {
    return {
      'row-delivered': rowEstado === estadoCompra.ENTREGADO,
      'row-partial-delivered': rowEstado === estadoCompra.PARCIAL
    };
  }

}
