import { Injectable } from '@angular/core';
import { FiltroOfertas } from '../components/filtros/filtro-ofertas/FiltroOfertas';
import { ElementoPor, Oferta } from '../types/ofertas';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ApiResponse } from '../types/api';
import { Tecnico, Cliente, Provincia } from '../types/ofertas';
import { map } from 'rxjs/operators';
import { Situaciones } from '../types/comandas';
import { Observable } from 'rxjs';
import { MAT_SORT_HEADER_INTL_PROVIDER } from '@angular/material/sort';
import { type AlbaranCompraChapa, type ComandaChapaDesdeBD, type ComandaChapaNueva, type CompraChapaDesdeBD, type CompraChapaNuevaFinal } from '../types/comprasChapa';


const idFields = ['idCiudad', 'idCliente', 'idProvincia', 'idTecnico', 'idComercial', 'idDelegacion', 'idIdioma', 'idOferta', 'idElementoPor', 'nOferta', 'idPais', 'idProducto', 'idSituaciones'];

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) { }

  private mapResponseConvertFieldsToString(response: ApiResponse<any[]>, fieldsToConvertFromNumberToString: string[]) {
    return ({
      ...response, response: response.response.map((oferta: any) => {
        const obj = {
          ...oferta,
        }
        fieldsToConvertFromNumberToString.forEach(field => {
          if ((obj[field] === 0 || obj[field]) && !isNaN(obj[field])) {
            obj[field] = obj[field].toString();
          }
        });
        return obj;
      })
    })
  }

  //Comanda
  anadirComanda(comanda: any) {
    const body = JSON.stringify({ comanda });
    return this.http.post<any>(environment.apiUrl + environment.ofertas + 'anadir-comanda', body).pipe(map(response => response));
  }

  modificarComanda(comanda: Oferta) {
    const body = JSON.stringify({ comanda });
    return this.http.post<any>(environment.apiUrl + environment.ofertas + 'upd-comanda', body).pipe(map(response => response));
  }

  borrarComanda(comanda: number) {
    const body = JSON.stringify({ comanda });
    return this.http.post<any>(environment.apiUrl + environment.ofertas + 'eliminar-comanda', body).pipe(map(response => response));
  }

  obtenerOfertas(filtros: FiltroOfertas) {
    const body = JSON.stringify({ filtros });
    return this.http.post<any>(environment.apiUrl + environment.ofertas + 'obtener-ofertes', body).pipe(map((response) => this.mapResponseConvertFieldsToString(response, idFields)));
  }

  carregarClientesByOferta(arrayOfertas: any) {
    const body = JSON.stringify({ arrayOfertas });
    return this.http.post<any>(environment.apiUrl + environment.ofertas + 'carregar-clientes-by-oferta', body).pipe(map((response) => this.mapResponseConvertFieldsToString(response, idFields)));
  }


  modificarPesoPrecio(campos: any) {
    const body = JSON.stringify({ campos });
    return this.http.post<any>(environment.apiUrl + environment.ofertas + 'modificar-pesoPrecio', body).pipe(map(response => response));
  }
  modificarPesoPrecioOpc(campos: any) {
    const body = JSON.stringify({ campos });
    return this.http.post<any>(environment.apiUrl + environment.ofertas + 'modificar-pesoPrecio-opc', body).pipe(map(response => response));
  }

  //comanda-Kits
  obtenerKitsUnaComanda(nComanda: number) {
    const body = JSON.stringify({ nComanda })
    console.log("BODY: ", body);
    return this.http.post<any>(environment.apiUrl + environment.ofertaKits + 'obtener-kits', body).pipe(map(response => response));
  }
  borrarKitsUnaComanda(kits: any) {
    const body = JSON.stringify({ kits })
    return this.http.post<any>(environment.apiUrl + environment.ofertaKits + 'eliminar-kit', body).pipe(map(response => response));
  }
  anadirKitsUnaComanda(kits: any) {
    const body = JSON.stringify({ kits })
    return this.http.post<any>(environment.apiUrl + environment.ofertaKits + 'anadir-kit', body).pipe(map(response => response));
  }
  modificarKitUnaComanda(kits: any) {
    const body = JSON.stringify({ kits })
    return this.http.post<any>(environment.apiUrl + environment.ofertaKits + 'modificar-kit', body).pipe(map(response => response));
  }

  calculo(kits: any) {
    const body = JSON.stringify({ kits })
    console.log("BODY: ", body);
    return this.http.post<any>(environment.apiUrl + environment.ofertaKits + 'calcular', body).pipe(map(response => response));
  }

  //comanda-Otros
  obtenerOtrosUnaComanda(nComanda: number) {
    const body = JSON.stringify({ nComanda })
    return this.http.post<any>(environment.apiUrl + environment.ofertaOtros + 'obtener-otros', body).pipe(map(response => response));
  }
  obtenerNoKitsNoOpcUnaComanda(nComanda: number) {
    const body = JSON.stringify({ nComanda })
    return this.http.post<any>(environment.apiUrl + environment.ofertaOtros + 'obtener-no-kits-no-otros', body).pipe(map(response => response));
  }
  borrarOtrosUnaComanda(otro: any) {
    const body = JSON.stringify({ otro })
    return this.http.post<any>(environment.apiUrl + environment.ofertaOtros + 'eliminar-otro', body).pipe(map(response => response));
  }
  anadirOtrosUnaComanda(otro: any) {
    const body = JSON.stringify({ otro })
    return this.http.post<any>(environment.apiUrl + environment.ofertaOtros + 'anadir-otro', body).pipe(map(response => response));
  }
  modificarOtroUnaComanda(otro: any) {
    const body = JSON.stringify({ otro })
    return this.http.post<any>(environment.apiUrl + environment.ofertaOtros + 'modificar-otro', body).pipe(map(response => response));
  }

  crearOtrosUnaComanda(nOferta: number) {
    const body = JSON.stringify({ nOferta });
    return this.http.post<any>(environment.apiUrl + environment.ofertaOtros + 'calcular-otros', body).pipe(map(response => response));
  }



  //Ofertas-Cliente
  obtenerClientesUnaOferta(nOferta: number) {
    const body = JSON.stringify({ nOferta });
    return this.http.post<any>(environment.apiUrl + environment.ofertasCliente + 'obtener-ofertes-by-comanda', body).pipe(map(response => response));
  }

  anadirClientesUnaOferta(cliente: any) {
    const body = JSON.stringify({ cliente });
    return this.http.post<any>(environment.apiUrl + environment.ofertasCliente + 'anadir-oferta', body).pipe(map(response => response));
  }

  modificarOferta(oferta: any) {
    const body = JSON.stringify({ oferta });
    console.log(body);
    return this.http.post<any>(environment.apiUrl + environment.ofertasCliente + 'modificar-oferta', body).pipe(map(response => response));
  }

  borrarOferta(oferta: any) {
    const body = JSON.stringify({ oferta });
    return this.http.post<any>(environment.apiUrl + environment.ofertasCliente + 'eliminar-oferta', body).pipe(map(response => response));
  }

  asignarOferta(oferta: any) {
    const body = JSON.stringify({ oferta });
    return this.http.post<any>(environment.apiUrl + environment.ofertasCliente + 'asingar-oferta', body).pipe(map(response => response));
  }

  obtenerProductosPreciosEspeciales(nOferta: any, nCliente: any) {
    const body = JSON.stringify({ nOferta, nCliente });
    return this.http.post<any>(environment.apiUrl + environment.ofertasCliente + 'obtener-productos-especiales', body).pipe(map(response => response));
  }

  obtenerProductosEspecialesOferta(nOferta: any) {
    const body = JSON.stringify({ nOferta });
    return this.http.post<any>(environment.apiUrl + environment.ofertasCliente + 'obtener-productos-especiales-oferta', body).pipe(map(response => response));
  }
  borrarProductosPreciosEspeciales(nOferta: any, nCliente: any, idProducto: any) {
    const body = JSON.stringify({ nOferta, nCliente, idProducto });
    return this.http.post<any>(environment.apiUrl + environment.ofertasCliente + 'delete-productos-especiales', body).pipe(map(response => response));
  }
  anadirProductosPreciosEspeciales(nOferta: any, nCliente: any, idProducto: any, cantidad: any) {
    const body = JSON.stringify({ nOferta, nCliente, idProducto, cantidad });
    return this.http.post<any>(environment.apiUrl + environment.ofertasCliente + 'insert-productos-especiales', body).pipe(map(response => response));
  }
  modificarProductosPreciosEspeciales(nOferta: any, nCliente: any, idProducto: any, cantidad: any) {
    const body = JSON.stringify({ nOferta, nCliente, idProducto, cantidad });
    return this.http.post<any>(environment.apiUrl + environment.ofertasCliente + 'update-productos-especiales', body).pipe(map(response => response));
  }
  detalleOfertaPDF(idOferta: any, idCliente: any) {
    const body = JSON.stringify({ idOferta, idCliente });
    return this.http.post<any>(environment.apiUrl + environment.ofertas + 'detalle-oferta-pdf', body);
  }
  obtenerPrecioFinal(idOferta: any, idCliente: any) {
    const body = JSON.stringify({ idOferta, idCliente });
    return this.http.post<any>(environment.apiUrl + environment.ofertas + 'obtener-precios-finales', body);
  }


  //Clientes gestion
  obtenerClientesCompleto(filtro: any) {
    const body = JSON.stringify({ filtro });
    return this.http.post<any>(environment.apiUrl + environment.clientes + 'obtener-all-clientes', body).pipe(map(response => response));
  }

  modificarCliente(cliente: any) {
    const body = JSON.stringify({ cliente });
    return this.http.post<any>(environment.apiUrl + environment.clientes + 'modificar-cliente', body).pipe(map(response => response));
  }

  anadirCliente(cliente: any) {
    const body = JSON.stringify({ cliente });
    return this.http.post<any>(environment.apiUrl + environment.clientes + 'anadir-cliente', body).pipe(map(response => response));
  }

  borrarCliente(cliente: any) {
    const body = JSON.stringify({ cliente });
    return this.http.post<any>(environment.apiUrl + environment.clientes + 'eliminar-cliente', body).pipe(map(response => response));
  }

  //Tecnicos gestion
  anadirTecnico(tecnico: any) {
    const body = JSON.stringify({ tecnico });
    return this.http.post<any>(environment.apiUrl + environment.tecnicos + 'anadir-tecnico', body).pipe(map(response => response));
  }
  modificarTecnico(tecnico: any) {
    const body = JSON.stringify({ tecnico });
    return this.http.post<any>(environment.apiUrl + environment.tecnicos + 'modificar-tecnico', body).pipe(map(response => response));
  }

  borrarTecnico(id: number) {
    const body = JSON.stringify({ id });
    return this.http.post<any>(environment.apiUrl + environment.tecnicos + 'eliminar-tecnico', body).pipe(map(response => response));
  }

  obtenerTecnicoFiltrado(params: any) {
    const body = JSON.stringify({ params });
    return this.http.post<any>(environment.apiUrl + environment.tecnicos + 'obtener-tecnicos', body).pipe(map(response => response));
  }

  //Albaranes Salida
  obtenerAlbaranesSalida(filtros: any) {
    const body = JSON.stringify({ filtros });
    return this.http.post<any>(environment.apiUrl + environment.albaranesSalida + 'obtener-albaranes', body).pipe(map((response) => this.mapResponseConvertFieldsToString(response, idFields)));
  }
  borrarAlbaranSalida(nAlbaran: any) {
    const body = JSON.stringify({ nAlbaran });
    return this.http.post<any>(environment.apiUrl + environment.albaranesSalida + 'eliminar-albaran', body).pipe(map(response => response));
  }
  modificarAlbaranSalida(albaranSalida: any) {
    const body = JSON.stringify({ albaranSalida });
    return this.http.post<any>(environment.apiUrl + environment.albaranesSalida + 'modificar-albaran', body).pipe(map(response => response));
  }
  anadirAlbaranSalida(albaranSalida: any) {
    const body = JSON.stringify({ albaranSalida });
    return this.http.post<any>(environment.apiUrl + environment.albaranesSalida + 'anadir-albaran', body).pipe(map(response => response));
  }
  obtenerLineasAlbaranSalida(nAlbaran: any) {
    const body = JSON.stringify({ nAlbaran });
    return this.http.post<any>(environment.apiUrl + environment.albaranesSalida + 'obtener-linias', body).pipe(map(response => response));
  }
  borrarLineaAlbaranSalida(params: any) {
    const body = JSON.stringify({ params });
    return this.http.post<any>(environment.apiUrl + environment.albaranesSalida + 'eliminar-linia', body).pipe(map(response => response));
  }
  modificarLineaAlbaranSalida(params: any) {
    const body = JSON.stringify({ params });
    return this.http.post<any>(environment.apiUrl + environment.albaranesSalida + 'modificar-linia', body).pipe(map(response => response));
  }
  anadirLineaAlbaranSalida(params: any) {
    const body = JSON.stringify({ params });
    return this.http.post<any>(environment.apiUrl + environment.albaranesSalida + 'anadir-linia', body).pipe(map(response => response));
  }
  generarLineasPendientesEnviar(params: any) {
    const body = JSON.stringify({ params });
    return this.http.post<any>(environment.apiUrl + environment.albaranesSalida + 'generar-linias', body).pipe(map(response => response));
  }
  obtenerKitsAlbaranPDF(idSortida: any) {
    const body = JSON.stringify({ idSortida });
    return this.http.post<any>(environment.apiUrl + environment.albaranesSalida + 'lista-kits-pdf', body);
  }
  obtenerProductosAlbaranPDF(idSortida: any) {
    const body = JSON.stringify({ idSortida });
    return this.http.post<any>(environment.apiUrl + environment.albaranesSalida + 'lista-prod-pdf', body);
  }
  obtenerHeaderAlbaranSalida(idSortida: any, tipo: any) {
    const body = JSON.stringify({ idSortida });
    console.log(tipo);
    if (tipo == 0) {
      console.log(tipo);
      return this.obtenerHeaderAlbaranEntrada(idSortida);
    } else {
      return this.http.post<any>(environment.apiUrl + environment.albaranesSalida + 'header-albara', body);
    }
  }



  //Albaranes Entrada
  obtenerAlbaranesEntrada(filtros: any) {
    const body = JSON.stringify({ filtros });
    return this.http.post<any>(environment.apiUrl + environment.albaranesEntrada + 'obtener-albaranes', body).pipe(map(response => response));
  }
  borrarAlbaranesEntrada(nAlbaran: any) {
    const body = JSON.stringify({ nAlbaran });
    return this.http.post<any>(environment.apiUrl + environment.albaranesEntrada + 'eliminar-albaran', body).pipe(map(response => response));
  }
  modificarAlbaranEntrada(albaranEntrada: any) {
    const body = JSON.stringify({ albaranEntrada });
    return this.http.post<any>(environment.apiUrl + environment.albaranesEntrada + 'modificar-albaran', body).pipe(map(response => response));
  }
  anadirAlbaranEntrada(albaranEntrada: any) {
    const body = JSON.stringify({ albaranEntrada });
    return this.http.post<any>(environment.apiUrl + environment.albaranesEntrada + 'anadir-albaran', body).pipe(map(response => response));
  }
  obtenerProductosDetalle(idComanda: any) {
    const body = JSON.stringify({ idComanda });
    return this.http.post<ApiResponse<any[]>>(environment.apiUrl + environment.albaranesEntrada + 'obtener-productos-detalle', body).pipe(map(response => response));
  }
  obtenerProductosDetalleDevolucion(idComanda: any) {
    const body = JSON.stringify({ idComanda });
    return this.http.post<ApiResponse<any[]>>(environment.apiUrl + environment.albaranesEntrada + 'obtener-productos-detalle-devolucion', body).pipe(map(response => response));
  }
  anadirUnProductoEntradaLn(producto: any) {
    const body = JSON.stringify({ producto });
    return this.http.post<any>(environment.apiUrl + environment.albaranesEntrada + 'anadir-productos-detalle', body).pipe(map(response => response));
  }
  modificarUnProductoEntradaLn(producto: any) {
    const body = JSON.stringify({ producto });
    return this.http.post<any>(environment.apiUrl + environment.albaranesEntrada + 'modificar-productos-detalle', body).pipe(map(response => response));
  }
  obtenerLineasAlbaranEntrada(nAlbaran: any) {
    const body = JSON.stringify({ nAlbaran });
    return this.http.post<any>(environment.apiUrl + environment.albaranesEntrada + 'obtener-linias-albaran', body).pipe(map(response => response));
  }
  borrarLineasAlbaranEntrada(producto: any) {
    const body = JSON.stringify({ producto });
    return this.http.post<any>(environment.apiUrl + environment.albaranesEntrada + 'eliminar-producto-detalle', body).pipe(map(response => response));
  }
  obtenerKitsAlbaranPDFEntrada(idSortida: any) {
    const body = JSON.stringify({ idSortida });
    return this.http.post<any>(environment.apiUrl + environment.albaranesEntrada + 'lista-kits-pdf', body);
  }
  obtenerProductosAlbaranPDFEntrada(idSortida: any) {
    const body = JSON.stringify({ idSortida });
    return this.http.post<any>(environment.apiUrl + environment.albaranesEntrada + 'lista-prod-pdf', body);
  }
  obtenerHeaderAlbaranEntrada(idSortida: any) {
    const body = JSON.stringify({ idSortida });
    return this.http.post<any>(environment.apiUrl + environment.albaranesEntrada + 'header-entrada', body);
  }




  //mantenimientos simples
  anadirSituacion(situacion: any) {
    const body = JSON.stringify({ situacion });
    return this.http.post<any>(environment.apiUrl + environment.ofertas + 'anadir-situacion', body).pipe(map(response => response));
  }
  borrarSituacion(idComandaUbic: any) {
    const body = JSON.stringify({ idComandaUbic })
    return this.http.post<any>(environment.apiUrl + environment.ofertas + 'eliminar-situacion', body).pipe(map((response) => this.mapResponseConvertFieldsToString(response, idFields)));
  }
  modificarSituacion(situacion: any) {
    const body = JSON.stringify({ situacion });
    return this.http.post<any>(environment.apiUrl + environment.ofertas + 'modificar-situacion', body).pipe(map(response => response));
  }
  anadirCondicionDePago(condicion: string) {
    const body = JSON.stringify({ condicion });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'anadir-condicion-de-pago', body).pipe(map(response => response));
  }
  borrarCondicionDePago(condicion: string) {
    const body = JSON.stringify({ condicion });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'eliminar-condicion-de-pago', body).pipe(map(response => response));
  }
  modificarCondicionDePago(condicion: any) {
    const body = JSON.stringify({ condicion });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'modificar-condicion-de-pago', body).pipe(map(response => response));
  }
  anadirValidezOferta(validez: string) {
    const body = JSON.stringify({ validez });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'anadir-validez-oferta', body).pipe(map(response => response));
  }
  borrarValidezOferta(validez: string) {
    const body = JSON.stringify({ validez });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'eliminar-validez-oferta', body).pipe(map(response => response));
  }
  modificarValidezOferta(validez: any) {
    const body = JSON.stringify({ validez });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'modificar-validez-oferta', body).pipe(map(response => response));
  }
  anadirFormaPago(formaPago: string) {
    const body = JSON.stringify({ formaPago });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'anadir-forma-de-pago', body).pipe(map(response => response));
  }
  borrarFormaPago(formaPago: string) {
    const body = JSON.stringify({ formaPago });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'eliminar-forma-de-pago', body).pipe(map(response => response));
  }
  modificarFormaPago(formaPago: any) {
    const body = JSON.stringify({ formaPago });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'modificar-forma-de-pago', body).pipe(map(response => response));
  }
  anadirPatologia(patologia: string) {
    const body = JSON.stringify({ patologia });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'anadir-patologia', body).pipe(map(response => response));
  }
  borrarPatologia(patologia: string) {
    const body = JSON.stringify({ patologia });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'eliminar-patologia', body).pipe(map(response => response));
  }
  modificarPatologia(patologia: any) {
    const body = JSON.stringify({ patologia });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'modificar-patologia', body).pipe(map(response => response));
  }
  anadirIdioma(idioma: string) {
    const body = JSON.stringify({ idioma });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'anadir-idioma', body).pipe(map(response => response));
  }
  borrarIdioma(idioma: string) {
    const body = JSON.stringify({ idioma });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'eliminar-idioma', body).pipe(map(response => response));
  }
  modificarIdioma(idioma: any) {
    const body = JSON.stringify({ idioma });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'modificar-idioma', body).pipe(map(response => response));
  }
  anadirPais(pais: string) {
    const body = JSON.stringify({ pais });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'anadir-pais', body).pipe(map(response => response));
  }
  borrarPais(pais: string) {
    const body = JSON.stringify({ pais });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'eliminar-pais', body).pipe(map(response => response));
  }
  modificarPais(pais: any) {
    const body = JSON.stringify({ pais });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'modificar-pais', body).pipe(map(response => response));
  }
  anadirEnvio(envio: string) {
    const body = JSON.stringify({ envio });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'anadir-envio', body).pipe(map(response => response));
  }
  borrarEnvio(envio: string) {
    const body = JSON.stringify({ envio });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'eliminar-envio', body).pipe(map(response => response));
  }
  modificarEnvio(envio: any) {
    const body = JSON.stringify({ envio });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'modificar-envio', body).pipe(map(response => response));
  }

  anadirTipoProducto(prod: string) {
    const body = JSON.stringify({ prod });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'anadir-tipo-producto', body).pipe(map(response => response));
  }
  borrarTipoProducto(prod: string) {
    const body = JSON.stringify({ prod });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'eliminar-tipo-producto', body).pipe(map(response => response));
  }
  modificarTipoProducto(prod: any) {
    const body = JSON.stringify({ prod });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'modificar-tipo-producto', body).pipe(map(response => response));
  }
  anadirTipoSoporte(soporte: string) {
    const body = JSON.stringify({ soporte });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'anadir-tipo-soporte', body).pipe(map(response => response));
  }
  borrarTipoSoporte(soporte: string) {
    const body = JSON.stringify({ soporte });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'eliminar-tipo-soporte', body).pipe(map(response => response));
  }
  modificarTipoSoporte(soporte: any) {
    const body = JSON.stringify({ soporte });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'modificar-tipo-soporte', body).pipe(map(response => response));
  }
  anadirTransportista(transportista: string) {
    const body = JSON.stringify({ transportista });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'anadir-transportista', body).pipe(map(response => response));
  }
  borrarTransportista(transportista: string) {
    const body = JSON.stringify({ transportista });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'eliminar-transportista', body).pipe(map(response => response));
  }
  modificarTransportista(transportista: any) {
    const body = JSON.stringify({ transportista });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'modificar-transportista', body).pipe(map(response => response));
  }
  borrarCuenta(cuenta: any) {
    const body = JSON.stringify({ cuenta });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'eliminar-cuenta', body).pipe(map(response => response));
  }
  modificarCuenta(cuenta: any) {
    const body = JSON.stringify({ cuenta });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'modificar-cuenta', body).pipe(map(response => response));
  }
  anadirCuenta(cuenta: string) {
    const body = JSON.stringify({ cuenta });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoSimple + 'anadir-cuenta', body).pipe(map(response => response));
  }




  //mantenimientos complejo
  anadirCiudad(ciudad: any) {
    const body = JSON.stringify({ ciudad });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'anadir-ciudad', body).pipe(map(response => response));
  }
  borrarCiudad(ciudad: any) {
    const body = JSON.stringify({ ciudad })
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'eliminar-ciudad', body).pipe(map(response => response));
  }
  modificarCiudad(ciudad: any) {
    const body = JSON.stringify({ ciudad });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'modificar-ciudad', body).pipe(map(response => response));
  }
  anadirProvincia(provincia: any) {
    const body = JSON.stringify({ provincia });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'anadir-provincia', body).pipe(map(response => response));
  }
  borrarProvincia(provincia: any) {
    const body = JSON.stringify({ provincia })
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'eliminar-provincia', body).pipe(map(response => response));
  }
  modificarProvincia(provincia: any) {
    const body = JSON.stringify({ provincia });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'modificar-provincia', body).pipe(map(response => response));
  }

  obtenerComponents(id: any) {
    const body = JSON.stringify({ id });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'obtener-components', body).pipe(map(response => response));
  }
  obtenerComponentsAuto() {
    return this.http.get<ApiResponse<any>>(environment.apiUrl + environment.matenimientoComplejo + 'obtener-components-desplegable');
  }
  modificarComponents(component: any) {
    const body = JSON.stringify({ component });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'modificar-components', body).pipe(map(response => response));
  }
  anadirComponents(component: any) {
    const body = JSON.stringify({ component });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'anadir-components', body).pipe(map(response => response));
  }
  borrarComponents(component: any) {
    const body = JSON.stringify({ component });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'eliminar-components', body).pipe(map(response => response));
  }

  anadirComercial(comercial: any) {
    const body = JSON.stringify({ comercial });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'anadir-comercial', body).pipe(map(response => response));
  }
  borrarComercial(comercial: any) {
    const body = JSON.stringify({ comercial })
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'eliminar-comercial', body).pipe(map(response => response));
  }
  modificarComercial(comercial: any) {
    const body = JSON.stringify({ comercial });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'modificar-comercial', body).pipe(map(response => response));
  }
  anadirTipoKit(tipoKit: any) {
    const body = JSON.stringify({ tipoKit });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'anadir-tipo-kit', body).pipe(map(response => response));
  }
  borrarTipoKit(tipoKit: any) {
    const body = JSON.stringify({ tipoKit })
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'eliminar-tipo-kit', body).pipe(map(response => response));
  }
  modificarTipoKit(tipoKit: any) {
    const body = JSON.stringify({ tipoKit });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'modificar-tipo-kit', body).pipe(map(response => response));
  }
  anadirProdMant(prodMant: any) {
    const body = JSON.stringify({ prodMant });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'anadir-producto-mant', body).pipe(map(response => response));
  }
  borrarProdMant(prodMant: any) {
    const body = JSON.stringify({ prodMant })
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'eliminar-producto-mant', body).pipe(map(response => response));
  }
  modificarProdMant(prodMant: any) {
    const body = JSON.stringify({ prodMant });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'modificar-producto-mant', body).pipe(map(response => response));
  }
  anadirSubTipusKit(subTipusKit: any) {
    const body = JSON.stringify({ subTipusKit });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'anadir-subtipo-kit', body).pipe(map(response => response));
  }
  borrarSubTipusKit(subTipusKit: any) {
    const body = JSON.stringify({ subTipusKit })
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'eliminar-subtipo-kit', body).pipe(map(response => response));
  }
  modificarSubTipusKit(subTipusKit: any) {
    const body = JSON.stringify({ subTipusKit });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'modificar-subtipo-kit', body).pipe(map(response => response));
  }
  anadirProdOpc(prodOpc: any) {
    const body = JSON.stringify({ prodOpc });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'anadir-producto-opc', body).pipe(map(response => response));
  }
  borrarProdOpc(prodOpc: any) {
    const body = JSON.stringify({ prodOpc })
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'eliminar-producto-opc', body).pipe(map(response => response));
  }
  modificarProdOpc(prodOpc: any) {
    const body = JSON.stringify({ prodOpc });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'modificar-producto-opc', body).pipe(map(response => response));
  }
  anadirkitMant(kitMant: any) {
    const body = JSON.stringify({ kitMant });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'anadir-kit-mant', body).pipe(map(response => response));
  }
  borrarkitMant(kitMant: any) {
    const body = JSON.stringify({ kitMant })
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'eliminar-kit-mant', body).pipe(map(response => response));
  }
  modificarkitMant(kitMant: any) {
    const body = JSON.stringify({ kitMant });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'modificar-kit-mant', body).pipe(map(response => response));
  }
  borrarDelegacion(delegacion: any) {
    const body = JSON.stringify({ delegacion })
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'eliminar-delegacio', body).pipe(map(response => response));
  }
  anadirDelegacion(delegacion: any) {
    const body = JSON.stringify({ delegacion })
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'anadir-delegacio', body).pipe(map(response => response));
  }
  modificarDelegacion(delegacion: any) {
    const body = JSON.stringify({ delegacion })
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'modificar-delegacio', body).pipe(map(response => response));
  }
  borrarProveedor(id: any) {
    const body = JSON.stringify({ id })
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'eliminar-proveedor', body).pipe(map(response => response));
  }
  anadirProveedor(proveedor: any) {
    const body = JSON.stringify({ proveedor });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'anadir-proveedor', body).pipe(map(response => response));
  }
  modificarProveedor(proveedor: any) {

    const body = JSON.stringify({ proveedor });
    return this.http.post<any>(environment.apiUrl + environment.matenimientoComplejo + 'modificar-proveedor', body).pipe(map(response => response));
  }




  //REPORTS MANTENIMIENTO COMPLEJO
  obtenerZincadoPdf() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.matenimientoComplejo + 'obtener-zincado-pdf');
  }





  //Encargado Obra
  obtenerEncargadoObra(idCliente: any) {
    const body = JSON.stringify({ idCliente });
    return this.http.post<any>(environment.apiUrl + environment.encargadoObra + 'obtener-all-encargado', body).pipe(map(response => response));
  }
  anadirEncargadoObra(encargado: any) {
    const body = JSON.stringify({ encargado });
    return this.http.post<any>(environment.apiUrl + environment.encargadoObra + 'anadir-encargado', body).pipe(map(response => response));
  }
  borrarEncargadoObra(idEncargado: any) {
    const body = JSON.stringify({ idEncargado });
    return this.http.post<any>(environment.apiUrl + environment.encargadoObra + 'eliminar-encargado', body).pipe(map(response => response));
  }
  modificarEncargadoObra(encargado: any) {
    const body = JSON.stringify({ encargado });
    return this.http.post<any>(environment.apiUrl + environment.encargadoObra + 'modificar-encargado', body).pipe(map(response => response));
  }

  // COMPRAS DE CHAPA 
  obtenerComprasChapa() {
    return this.http.get<ApiResponse<CompraChapaDesdeBD[]>>(environment.apiUrl + environment.comprasChapa + 'obtener-compras-chapa');
  }

  anadirCompraChapa(compra: CompraChapaNuevaFinal) {
    const body = JSON.stringify({ compra });
    return this.http.post<ApiResponse<any>>(environment.apiUrl + environment.comprasChapa + 'anadir-compra-chapa', body).pipe(map(response => response));
  }

  actualizarCompraChapa(idCompra: number, tipo: string,  precio: number) {
    const body = JSON.stringify({ idCompra, tipo, precio });
    return this.http.post<ApiResponse<any>>(environment.apiUrl + environment.comprasChapa + 'actualizar-compra-chapa', body).pipe(map(response => response));
  }

  borrarCompraChapa(idCompra: number) {
    const body = JSON.stringify({ idCompra });
    return this.http.post<ApiResponse<any>>(environment.apiUrl + environment.comprasChapa + 'borrar-compra-chapa', body).pipe(map(response => response));
  }

  obtenerCompraChapaDetalle(idCompra: number) {
    const body = JSON.stringify({ idCompra });
    return this.http.post<ApiResponse<ComandaChapaDesdeBD[]>>(environment.apiUrl + environment.comprasChapa + 'obtener-compra-chapa-detalle', body).pipe(map(response => response));
  }

  obtenerAlbaranesDeCompraChapa(idComanda: number) {
    const body = JSON.stringify({ idComanda });
    return this.http.post<ApiResponse<any[]>>(environment.apiUrl + environment.comprasChapa + 'obtener-albaranes-compra-chapa', body).pipe(map(response => response));
  }

  obtenerAlbaranesCompraChapaPorFecha(fechaDesde: string, fechaHasta: string) {
    const body = JSON.stringify({ fechaDesde, fechaHasta });
    return this.http.post<ApiResponse<any[]>>(environment.apiUrl + environment.comprasChapa + 'obtener-albaranes-compra-chapa-por-fecha', body).pipe(map(response => response));
  }

  anadirAlbaranCompraChapa(body: AlbaranCompraChapa) {
    return this.http.post<any>(environment.apiUrl + environment.comprasChapa + 'anadir-albaran-compra-chapa', body).pipe(map(response => response));
  }

  modificarEstadoCompraChapaDetalle(idCompra: number, idComanda: number, idEstado: number, units: number, pesAlta: number) {
    const body = JSON.stringify({ idCompra, idComanda, idEstado, units, pesAlta });
    return this.http.post<any>(environment.apiUrl + environment.comprasChapa + 'modificar-estado-compra-chapa-detalle', body).pipe(map(response => response));
  }

  obtenerChapas() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.comprasChapa + 'obtener-chapas');
  }

  anadirComandaChapa(comanda: ComandaChapaNueva) {
    const body = JSON.stringify({ comanda });
    return this.http.post<ApiResponse<any>>(environment.apiUrl + environment.comprasChapa + 'anadir-comanda-compra-chapa', body).pipe(map(response => response));
  }

  borrarComandaCompraChapa(idComanda: number) {
    const body = JSON.stringify({ idComanda });
    return this.http.post<ApiResponse<any>>(environment.apiUrl + environment.comprasChapa + 'borrar-comanda-compra-chapa', body).pipe(map(response => response));
  }

  // Órdenes de elaboración
  obtenerOrdenesElaboracion() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.ordenesElaboracion + 'obtener-ordenes-elaboracion');
  }

  obtenerOrdenElaboracionDetalle(idOrden: number) {
    const body = JSON.stringify({ idOrden });
    return this.http.post<ApiResponse<any[]>>(environment.apiUrl + environment.ordenesElaboracion + 'obtener-orden-elaboracion-detalle', body).pipe(map(response => response));
  }

  //Fabricación
  obtenerTiposProductoFabricacion() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.fabricacion + 'obtener-tipos-producto-fabricacion');
  }

  obtenerMedidasProducto(seFabrica: number, idProductoFabricacion: number) {
    const body = JSON.stringify({ seFabrica, idProductoFabricacion });
    return this.http.post<ApiResponse<any[]>>(environment.apiUrl + environment.fabricacion + 'obtener-medidas', body).pipe(map(response => response));
  }

  obtenerModelosProducto(seFabrica: number, idProductoFabricacion: number, idMedida: number) {
    const body = JSON.stringify({ seFabrica, idProductoFabricacion, idMedida });
    return this.http.post<ApiResponse<any[]>>(environment.apiUrl + environment.fabricacion + 'obtener-modelos', body).pipe(map(response => response));
  }

  obtenerLongitudesProducto(seFabrica: number, idProductoFabricacion: number, idMedida: number, idModelo: number) {
    const body = JSON.stringify({ seFabrica, idProductoFabricacion, idMedida, idModelo });
    return this.http.post<ApiResponse<any[]>>(environment.apiUrl + environment.fabricacion + 'obtener-longitudes', body).pipe(map(response => response));
  }

  insertOrderFabrication(order: any, idChapa: number, cantidadDeChapas: number) {
    const body = JSON.stringify({ order, idChapa, cantidadDeChapas });
    return this.http.post<any>(environment.apiUrl + environment.fabricacion + 'insert-order-fabrication', body).pipe(map(response => response));
  }

  updateStockPlegados(productos:any) {
    const body = JSON.stringify({ productos });
    return this.http.post<any>(environment.apiUrl + environment.fabricacion + 'update-stock-plegados', body).pipe(map(response => response));
  }

  obtenerProductosAPlegar() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.fabricacion + 'obtener-productos-a-plegar');
  }

  //Ordenes Compra
  comprar(ordenCompra: any) {
    const body = JSON.stringify({ ordenCompra });
    return this.http.post<any>(environment.apiUrl + environment.ordenCompra + 'comprar', body).pipe(map(response => response));
  }
  obtenerOrdenesCompra(ordenCompra: any) {
    const body = JSON.stringify({ ordenCompra });
    return this.http.post<any>(environment.apiUrl + environment.ordenCompra + 'obtener-compra', body).pipe(map(response => response));
  }

  obtenerOrdenesCompraDetalle(nCompra: any) {
    const body = JSON.stringify({ nCompra });
    return this.http.post<any>(environment.apiUrl + environment.ordenCompra + 'obtener-compra-detalle', body).pipe(map(response => response));
  }
  anadirOrdenCompraDetalle(ordenCompra: any) {
    const body = JSON.stringify({ ordenCompra });
    return this.http.post<any>(environment.apiUrl + environment.ordenCompra + 'anadir-compra-detalle', body).pipe(map(response => response));
  }

  anadirOrdenCompraDetalle2(ordenCompra: any) {
    const body = JSON.stringify({ ordenCompra });
    return this.http.post<any>(environment.apiUrl + environment.ordenCompra + 'anadir-compra-detalle2', body).pipe(map(response => response));
  }
  modificarOrdenCompraDetalle(ordenCompra: any) {
    const body = JSON.stringify({ ordenCompra });
    return this.http.post<any>(environment.apiUrl + environment.ordenCompra + 'modificar-compra-detalle', body).pipe(map(response => response));
  }

  modificarOrdenCompraDetalle2(ordenCompra: any) {
    const body = JSON.stringify({ ordenCompra });
    return this.http.post<any>(environment.apiUrl + environment.ordenCompra + 'modificar-compra-detalle2', body).pipe(map(response => response));
  }

  borrarOrdenCompraDetalle(ordenCompra: any) {
    const body = JSON.stringify({ ordenCompra });
    return this.http.post<any>(environment.apiUrl + environment.ordenCompra + 'eliminar-compra-detalle', body).pipe(map(response => response));
  }

  //GENERAR ORDENES COMPRA
  obtenerOrdenCompraAutogenerada() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.generarCompra + 'obtener-orden-compra-generada');
  }
  generarOrdenCompra() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.generarCompra + 'generar-orden-compra');
  }
  insertOrdenCompra(ordenCompra: any) {
    const body = JSON.stringify({ ordenCompra });
    return this.http.post<any>(environment.apiUrl + environment.generarCompra + 'nuevo-producto', body);
  }
  modificarOrdenCompra(ordenCompra: any) {
    const body = JSON.stringify({ ordenCompra });
    return this.http.post<any>(environment.apiUrl + environment.generarCompra + 'modificar-orden-compra', body);
  }


  //Precios Producto
  obtenerPrecioProducto(idProd: number) {
    const body = JSON.stringify({ idProd });
    return this.http.post<any>(environment.apiUrl + environment.preciosProducto + 'obtener-precio-producto', body).pipe(map(response => response));
  }
  modificarPrecioProducto(pvp: any) {
    const body = JSON.stringify({ pvp });
    return this.http.post<any>(environment.apiUrl + environment.preciosProducto + 'modificar-precio-producto', body).pipe(map(response => response));
  }
  anadirPrecioProducto(pvp: any) {
    const body = JSON.stringify({ pvp });
    return this.http.post<any>(environment.apiUrl + environment.preciosProducto + 'anadir-precio-producto', body).pipe(map(response => response));
  }
  modificarPrecioProductoCompleto(pvp: any) {
    const body = JSON.stringify({ pvp });
    return this.http.post<any>(environment.apiUrl + environment.preciosProducto + 'modificar-precio-producto-completo', body).pipe(map(response => response));
  }
  anadirPrecioProductoCompleto(pvp: any) {
    const body = JSON.stringify({ pvp });
    return this.http.post<any>(environment.apiUrl + environment.preciosProducto + 'anadir-precio-producto-completo', body).pipe(map(response => response));
  }
  eliminarPrecioProducto(pvp: any) {
    const body = JSON.stringify({ pvp });
    return this.http.post<any>(environment.apiUrl + environment.preciosProducto + 'eliminar-precio-producto', body).pipe(map(response => response));
  }


  //Stock en proceso de fabricacion

  obtenerStockWIP() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.stock + 'obtener-stock');
  }
  anadirStock(stock: any) {
    const body = JSON.stringify({ stock });
    return this.http.post<any>(environment.apiUrl + environment.stock + 'anadir-stock', body);
  }
  modificarStock(stock: any) {
    const body = JSON.stringify({ stock });
    return this.http.post<any>(environment.apiUrl + environment.stock + 'modificar-stock', body);
  }
  eliminarStock(id: any) {
    const body = JSON.stringify({ id });
    return this.http.post<any>(environment.apiUrl + environment.stock + 'eliminar-stock', body);
  }
  obtenerProductosStock() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.stock + 'obtener-productos-stock');
  }

  //ControlStock

  obtenerControlStock(stock: any) {
    const body = JSON.stringify({ stock });
    return this.http.post<any>(environment.apiUrl + environment.stock + 'obtener-control-stock', body);
  }

  obtenerControlStock2(stock: any) {
    const body = JSON.stringify({ stock });
    return this.http.post<any>(environment.apiUrl + environment.stock + 'obtener-control-stock2', body);
  }


  //Mantenimiento de stock en produccion
  guardarStockProducto(producto: any) {
    const body = JSON.stringify({ producto });
    return this.http.post<any>(environment.apiUrl + environment.stock + 'guardar-stock-producto', body);
  }

  obtenerProductosAlZincador() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.stock + 'obtener-productos-al-zincador');
  }

  eliminarProductoAlZincador(id: number) {
    const body = JSON.stringify({ id });
    return this.http.post<any>(environment.apiUrl + environment.stock + 'eliminar-producto-al-zincador', body);
  }
  //

  //Facturas
  eliminarFactura(factura: any) {
    const body = JSON.stringify({ factura });
    return this.http.post<any>(environment.apiUrl + environment.factura + 'eliminar-factura', body);
  }
  modificarFactura(factura: any) {
    const body = JSON.stringify({ factura });
    console.log(body);
    return this.http.post<any>(environment.apiUrl + environment.factura + 'modificar-factura', body);
  }
  anadirFactura(factura: any) {
    const body = JSON.stringify({ factura });
    return this.http.post<any>(environment.apiUrl + environment.factura + 'anadir-factura', body);
  }
  obtenerFactura(params: any) {
    const body = JSON.stringify({ params });
    return this.http.post<any>(environment.apiUrl + environment.factura + 'obtener-facturas', body);
  }
  nuevaFactura() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.factura + 'nueva-factura');
  }

  crearFactura(factura: any) {
    //factura ha de tener nComanda, nFactura, iva
    const body = JSON.stringify({ factura });
    return this.http.post<any>(environment.apiUrl + environment.factura + 'anadir-factura-albaran', body);
  }

  crearFacturaVacia() {
    //factura ha de tener nComanda = 0, nFactura = null, iva, 
    return this.http.get<any>(environment.apiUrl + environment.factura + 'anadir-factura-vacia');
  }

  obtenerAlbaranFacturable(idComanda: any) {
    //idComanda es nomes el idComanda
    const body = JSON.stringify({ idComanda });
    return this.http.post<any>(environment.apiUrl + environment.factura + 'obtener-facturables-by-comanda', body);
  }
  obtenerFacturaLn(idFactura: any) {
    const body = JSON.stringify({ idFactura });
    return this.http.post<any>(environment.apiUrl + environment.factura + 'obtener-facturaln', body);
  }

  anadirFacturaLn(factura: any) {
    const body = JSON.stringify({ factura });
    return this.http.post<any>(environment.apiUrl + environment.factura + 'anadir-factura-linea', body);
  }
  eliminarFacturaLn(factura: any) {
    const body = JSON.stringify({ factura });
    return this.http.post<any>(environment.apiUrl + environment.factura + 'eliminar-factura-linea', body);
  }

  //facturas Report
  obtenerFacturaNoVacia(idFactura: any) {
    const body = JSON.stringify({ idFactura });
    return this.http.post<any>(environment.apiUrl + environment.factura + 'imprimir-no-vacia', body);
  }
  obtenerMaterialVendido(ano: any, tipoProducto: any) {
    const body = JSON.stringify({ "ano": ano, "tipoProducto": tipoProducto });
    console.log("BODY: ", body);
    return this.http.post<any>(environment.apiUrl + environment.factura + 'material-vendido', body);
  }
  obtenerDatosClientes(idCliente: any) {
    const body = JSON.stringify({ idCliente });
    return this.http.post<any>(environment.apiUrl + environment.obtenciones + 'header-cliente', body);
  }
  modificarDescripcionPdf(idFactura: any, descripcion: any) {
    let aux = {
      "idFactura": idFactura,
      "descripcion": descripcion
    };
    const body = JSON.stringify(aux);
    console.log(body);
    return this.http.post<any>(environment.apiUrl + environment.factura + 'modificar-descripcion-pdf', body);
  }



  //filtros-Obtencion 
  obtenerComerciales() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-comercials');
  }

  obtenerTecnicos() {
    return this.http.get<ApiResponse<Tecnico[]>>(environment.apiUrl + environment.obtenciones + 'obtener-tecnics');
  }

  obtenerClientes() {
    return this.http.get<ApiResponse<Cliente[]>>(environment.apiUrl + environment.obtenciones + 'obtener-clients');
  }

  obtenerProvincias() {
    return this.http.get<ApiResponse<Provincia[]>>(environment.apiUrl + environment.obtenciones + 'obtener-provincies');
  }

  obtenerCiudades() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-ciudad');
  }
  obtenerCiudadesProvincias() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-ciudad-provincia');
  }
  obtenerProvinciasPais() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-provincia-pais');
  }

  obtenerElementoPor() {
    return this.http.get<ApiResponse<ElementoPor[]>>(environment.apiUrl + environment.obtenciones + 'obtener-elemPor');
  }

  obtenerDelegacion() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-delegacio');
  }
  obtenerDelegacionParams(idDelegacio: any) {
    const body = JSON.stringify({ idDelegacio });
    return this.http.post<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-delegacio-params', body).pipe(map(response => response));
  }

  obtenerIdioma() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-idioma');
  }

  obtenerProvinciaByPais(idPais: any) {
    const body = JSON.stringify({ idPais });
    return this.http.post<any>(environment.apiUrl + environment.obtenciones + 'obtener-provincia-by-pais', body).pipe(map(response => response));
  }

  obtenerProvinciaByCiudad(idCiudad: any) {
    const body = JSON.stringify({ idCiudad });
    return this.http.post<any>(environment.apiUrl + environment.obtenciones + 'obtener-provincia-by-ciutat', body).pipe(map(response => response));
  }

  obtenerCiudadByProvincia(idProv: any) {
    const body = JSON.stringify({ idProv });
    return this.http.post<any>(environment.apiUrl + environment.obtenciones + 'obtener-ciudad-by-provincia', body).pipe(map(response => response));
  }
  obtenerPais() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-pais');
  }
  obtenerInfoCliente(id: any) {
    const body = id;
    return this.http.post<any>(environment.apiUrl + environment.obtenciones + 'obtener-info-clients', body).pipe(map(response => response));
  }

  obtenerProductosKit(nOferta: number) {
    const body = JSON.stringify({ nOferta });
    return this.http.post<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-productos-kit', body).pipe(map(response => response));
  }

  //obtener especiales
  obtenerProductosNoKit(nOferta: number) {
    const body = JSON.stringify({ nOferta });
    return this.http.post<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-productos-no-kit', body).pipe(map(response => response));
  }
  obtenerProductosNoKitAlbaran(nOferta: number) {
    const body = JSON.stringify({ nOferta });
    return this.http.post<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-productos-no-kit-albaran', body).pipe(map(response => response));
  }
  //obtener otros
  obtenerProductosNoKitNoOpc(nOferta: number) {
    const body = JSON.stringify({ nOferta });
    return this.http.get(environment.apiUrl + environment.obtenciones + 'obtener-productos-no-kit-no-opc');
  }
  obtenerSituacionComanda(nOferta: number) {
    const body = JSON.stringify({ nOferta });
    return this.http.post<ApiResponse<Situaciones[]>>(environment.apiUrl + environment.obtenciones + 'obtener-situaciones', body).pipe(map(response => response));
  }
  obtenerCondicionesDePago() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-condiciones-de-pago');
  }
  obtenerValidezOferta() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-validez-oferta');
  }
  obtenerEstados() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-estados');
  }
  obtenerFormaPago() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-forma-pago');
  }
  obtenerPatologia() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-patologia');
  }
  obtenerTransportistas() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-transportistas');
  }
  obtenerEnvio() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-envio');
  }
  obtenerTipoProducto() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-tipo-producto');
  }
  obtenerTipoProductoStock() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-tipo-producto-stock');
  }
  obtenerTipoSoporte() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-tipo-soporte');
  }
  obtenerTipoKits() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-tipo-kit');
  }
  obtenerSubTipoKit() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-subtipo-kit');
  }
  obtenerMorteroMant() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-mortero-mant');
  }
  obtenerResinaMant() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-resina-mant');
  }
  obtenerVarillaMant() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-varilla-mant');
  }
  obtenerCementoPortlandMant() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-cemento-portland-mant');
  }
  obtenerCementoEspumanteMant() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-cemento-espumante-mant');
  }
  obtenerProductosMant() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-productos-mantenimiento');
  }
  obtenerProductosOrdenCompra() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-productos-orden-compra');
  }
  obtenerProductosOtros() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-productos-otros');
  }
  obtenerTipoProductosOpc() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-tipo-productos-opc');
  }
  obtenerUnidadMedida() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-unidad-medida');
  }
  obtenerSubTipo() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-subtipos');
  }
  obtenerKitMant() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-kits-mant');
  }
  obtenerKitReal() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-kits-real');
  }
  obtenerProveedor() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-proveedor');
  }
  obtenerProveedorParams(id: any) {
    const body = JSON.stringify({ id });
    console.log("obtener");
    return this.http.post(environment.apiUrl + environment.obtenciones + 'obtener-proveedor-report', body);
  }
  obtenerCuenta() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-cuenta');
  }
  obtenerTipo1() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.stock + 'tipo1');
  }
  obtenerTipo3() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.stock + 'tipo3');
  }

  obtenerMVV() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.stock + 'mvv');
  }
  obtenerMVH() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.stock + 'mvh');
  }

  obtenerProductosFabricacion() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-productos-fabricacion');
  }

  obtenerProductosGenericosFabricacion() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-productos-genericos-fabricacion');
  }

  obtenerMedidasProductoFabricacion() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-medidas-producto-fabricacion');
  }

  obtenerModelosProductoFabricacion() {
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + environment.obtenciones + 'obtener-modelos-producto-fabricacion');
  }


  //login
  login(user: any): Observable<any> {
    console.log("Entro en login.servicio")
    const body = JSON.stringify({ user });
    return this.http.post(environment.apiUrl + environment.login + 'login', body);
  }

  //upload image

  public uploadFile(data: any) {
    return this.http.post<any>(environment.uploadFile, data);
  }

}
