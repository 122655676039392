import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { IdLabelObject } from '../../../types/global';
import { ApiService } from '../../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DialogPopUpComponent } from 'src/app/dialogs/dialog-pop-up/dialog-pop-up.component';
import { MatDialog } from '@angular/material/dialog';
import { AlbaranSalidaLn } from '../../../types/stock';
import { TransitionCheckState } from '@angular/material/checkbox';
import { TituloService } from 'src/app/services/titulo.service';
import { mantenimiento2Componente } from 'src/app/services/llamadasMantenimiento';

@Component({
  selector: 'app-detalles-salida',
  templateUrl: './detalles-salida.component.html',
  styleUrls: ['./detalles-salida.component.css']
})
export class DetallesSalidaComponent implements OnInit, AfterViewInit {

  public listaDetalleProductos: any[] = [];
  public productosKits: IdLabelObject[] = [];
  public productosOtros: IdLabelObject[] = [];
  public crearProductoNuevo: boolean = false;
  private paramsUrl: any;
  public booleanEsKit: boolean = true;
  public detalleUnProductoABorrar: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public productoResaltado = null;
  public dataSource!: MatTableDataSource<any>;
  public nOferta: any;
  public nAlbaran: any;
  public estado: any;

  public detalleUnProducto: any = {
    nAlbaran: "",
    nOferta: '',
    idProducto: "",
    nombreProducto: "",
    cantidadOferta: "",
    cantidadEnviada: "",
    cantidadDevuelta: "",
    cantidadAlbaran: "",
    esKit: "",
    idProducte: "",
    idSortida: ""
  }

  displayedColumns: string[] = ['buttons', 'nombreProducto', 'cantidadOferta', 'cantidadEnviada', 'cantidadDevuelta', 'cantidadAlbaran', 'esKit'];

  constructor(
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private tituloService: TituloService
  ) {
    this.dataSource = new MatTableDataSource(this.listaDetalleProductos);
  }


  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.resetDetalleUnProducto();
    this.activatedRoute.queryParams.subscribe((params) => {
      this.paramsUrl = params;
      this.nOferta = this.paramsUrl.nOferta;
      this.nAlbaran = this.paramsUrl.nAlbaran;
      if(this.paramsUrl.estado === "Entregado") {
        this.estado = false;
      } else { this.estado = true}  
      this.apiService.obtenerProductosKit(params.nOferta).subscribe(data => {// TODO cambiar peticion api
        this.productosKits = data.response.map(producto => ({ id: producto.idProducto.toString(), label: producto.nombre }));
      });
      this.apiService.obtenerProductosNoKitAlbaran(params.nOferta).subscribe(data => {// TODO cambiar peticion api
        this.productosOtros = data.response.map(producto => ({ id: producto.idProducto.toString(), label: producto.nombre }));
      });
//      this.obtenerLineasAlbaranSalida();
    })
  }

  ngAfterViewInit(): void {
    this.obtenerLineasAlbaranSalida();
/*    this.dataSource = new MatTableDataSource<any>(this.listaDetalleProductos);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;*/
  }

/*  ngOnChanges(): void {
    this.dataSource = new MatTableDataSource<any>(this.listaDetalleProductos);
    let page = this.buscarIndiceEnOfertasFiltradas(this.productoResaltado) || 0;
    this.paginator.pageIndex = page;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }*/

  public muestradetalleProducto(element: any) {
    this.crearProductoNuevo = false;
    this.detalleUnProducto = { ...element };
    if (this.detalleUnProducto.esKit === "SI") { this.booleanEsKit = true }
    else { this.booleanEsKit = false };
  }

  public esDetalleProductoNuevo() {
    this.crearProductoNuevo = true;
    this.resetDetalleUnProducto();
  }

  public guardarDetalleProducto(formulario: NgForm) {
    var msg = "";
 /*   if (!this.miraNegativos(this.detalleUnProducto.cantidadAlbaran)) {
      msg = 'El campo Cantidad Albarán tiene un número negativo';
    }
    else*/ if (this.comprobarSiYaExiste(this.detalleUnProducto.idProducto) && this.crearProductoNuevo) {
      msg = 'Este producto ya esta creado';
    }

    if (msg != "") {
      this.openDialog("ALERTA", msg, false, "");
      return;
    }

    if (this.crearProductoNuevo) {
      if (this.booleanEsKit) this.detalleUnProducto.esKit = "SI";
      else this.detalleUnProducto.esKit = "NO";
      
      this.detalleUnProducto.nAlbaran = this.paramsUrl.nAlbaran;
      this.detalleUnProducto.nOferta = this.nOferta;
      this.detalleUnProducto.idProducte = parseInt(this.detalleUnProducto.idProducto);
      this.detalleUnProducto.idSortida = parseInt(this.detalleUnProducto.nAlbaran);
      console.log("detalleUnProducto en ALTA: ", this.detalleUnProducto);
      this.apiService.anadirLineaAlbaranSalida(this.detalleUnProducto).subscribe(data => {
        console.log("Data: ", data);
        this.productoResaltado = this.detalleUnProducto.idProducto;
        this.crearProductoNuevo = false;
        this.obtenerLineasAlbaranSalida();
      });
    } else {
      this.detalleUnProducto.nAlbaran = this.paramsUrl.nAlbaran;
      this.detalleUnProducto.nOferta = this.nOferta;
      console.log("detalleUnProducto en MODIFCAR: ", this.detalleUnProducto);
      this.apiService.modificarLineaAlbaranSalida(this.detalleUnProducto).subscribe(dades => {
        console.log("Data: ", dades);
        this.resetDetalleUnProducto();
        this.obtenerLineasAlbaranSalida();
      });
    }
}

  public volverAAlbaranesSalida() {
    this.router.navigate(['albaranes-salida'], { queryParams: this.paramsUrl })
  }

  public eliminarDetalleProducto() {
    this.detalleUnProductoABorrar.nAlbaran = this.paramsUrl.nAlbaran;
    this.detalleUnProductoABorrar.nOferta = this.paramsUrl.nOferta;
    this.apiService.borrarLineaAlbaranSalida(this.detalleUnProductoABorrar).subscribe(dades => {
      console.log("PASO")
      this.crearProductoNuevo = false;
      this.apiService.obtenerLineasAlbaranSalida(this.nAlbaran).subscribe(data => {
        this.obtenerLineasAlbaranSalida();
        this.obtenerLineasAlbaranSalida();
        this.resetDetalleUnProducto();
      })
    })

  }

  public dialogEliminarDetalleProducto(detalleUnProducto: any) {
    this.detalleUnProductoABorrar = detalleUnProducto;
    this.openDialog("ELIMINAR PRODUCTO", "¿Quiere eliminar el producto " + detalleUnProducto.nombreProducto + " de la lista", true, "eliminarDetalleProducto");
  }



  openDialog(title: string, msg: string, cancelBtn: boolean, tipo: any)  {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      data: { title: title, msg: msg, cancelBtn: cancelBtn }
    });
    dialogRef.afterClosed().subscribe(result => {
      switch (tipo) {
        case 'eliminarDetalleProducto':
          if (result) this.eliminarDetalleProducto();
          break;
        default:
          break;
      }
    });
  }

  public generarLineasPendientes() {
    let datos = {
      nAlbaran: this.nAlbaran,
      nOferta: this.nOferta
    }
    this.apiService.generarLineasPendientesEnviar(datos).subscribe(dades => {
      this.obtenerLineasAlbaranSalida();
    })
  }

  private comprobarSiYaExiste(idProducto: string) {
    let arrayComprobado;
    arrayComprobado = this.listaDetalleProductos.find(item => {
      if (item.idProducto == idProducto) {
        return true;
      } else {
        return false;
      }
    });
    return arrayComprobado;
  }

private miraNegativos(numero: number) {
    if(numero < 0) {
      return false
    } else {
      return true
    }
  }

  private obtenerLineasAlbaranSalida() {
    this.apiService.obtenerLineasAlbaranSalida(this.nAlbaran).subscribe(data => {
      this.listaDetalleProductos = data.response;
      this.dataSource = new MatTableDataSource<AlbaranSalidaLn>(this.listaDetalleProductos);
      let page = this.buscarIndiceEnOfertasFiltradas(this.productoResaltado) || 0;
      this.paginator.pageIndex = page;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })

  }


  public cuandoSeCliqueEnMantenimiento(label: string) {
    this.router.navigate([label]);
  }

  private buscarIndiceEnOfertasFiltradas(productoResaltado: any) {
    if (!productoResaltado) return 0;
    let busqueda = productoResaltado.toString();
    let indice: number;
    indice = this.listaDetalleProductos.findIndex(albaran => albaran.nAlbaranSalida.toString() === busqueda);
    return Math.trunc((indice - 1) / this.paginator.pageSize);
  }


  private resetDetalleUnProducto() {
    this.detalleUnProducto = {
      idProducto: "",
      nombreProducto: "",
      cantidadOferta: "",
      cantidadEnviada: "",
      cantidadDevuelta: "",
      cantidadAlbaran: "",
      esKit: ""
    }
    this.booleanEsKit = true;
  }

  public muestraComponents() {
    console.log("Muestra componentes");
    for(let i in this.productosKits) {
      if(this.productosKits[i].id === this.detalleUnProducto.idProducto) {
        mantenimiento2Componente(this.dialog, this.detalleUnProducto.idProducto, this.productosKits[i].label, true);
        break;
      }
    }
  }
}
