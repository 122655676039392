<h2 class="h2titulo flex flex-65-responsive justify-center">Lista Kits de la Oferta Nº {{oferta.nOferta}}</h2> <br>

<div class="container flex-layout">

    <div class="layout-tabla flex-layout flex-65-responsive">
        <table mat-table [dataSource]="dataSource" matSort
            class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">

            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>
                    <button class="button-create" *ngIf="!idClienteAsignado" mat-icon-button type="button"
                        (click)="esKitNuevoEnOferta()">
                        <mat-icon>create_new_folder</mat-icon>
                    </button>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="!idClienteAsignado" mat-icon-button type="button"
                        (click)="dialogEliminarKitEnOferta( element)">
                        <mat-icon>deletes</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="producto">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Productos-Kits </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.nombreProducto}} </td>
            </ng-container>

            <ng-container matColumnDef="situacion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Situación </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.situacion}} </td>
            </ng-container>

            <ng-container matColumnDef="cantidad">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> Cantidad </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.cantidad}} </td>
            </ng-container>

            <ng-container matColumnDef="precioUnitario">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> Precio Unitario </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.precioUnitario}} </td>
            </ng-container>

            <ng-container matColumnDef="pesoUnitario">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> Peso Unitario </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.pesoUnitario | number:'0.2-2'}} </td>
            </ng-container>
            <!--
            <ng-container matColumnDef="interaje">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> Intereje </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.interaje | number:'0.2-2'}} </td>
            </ng-container>

            <ng-container matColumnDef="luz">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> Luz </th>
                <td mat-cell *matCellDef="let element" align="right"> {{element.luz | number:'0.2-2'}} </td>
            </ng-container>

            <ng-container matColumnDef="cargaRepartidaAP">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> cargaRepartida Acciones Permanentes </th>
                <td mat-cell *matCellDef="let element" align="right"> {{element.cargaRepartidaAP | number:'0.2-2'}} </td>
            </ng-container>

            <ng-container matColumnDef="cargaRepartidaAV">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> cargaRepartida Acciones Variables </th>
                <td mat-cell *matCellDef="let element" align="right"> {{element.cargaRepartidaAV | number:'0.2-2'}} </td>
            </ng-container>

            <ng-container matColumnDef="cargaPuntualAP">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> cargaPuntual Acciones Permanentes </th>
                <td mat-cell *matCellDef="let element" align="right"> {{element.cargaPuntualAP | number:'0.2-2'}} </td>
            </ng-container>

            <ng-container matColumnDef="cargaPuntualAV">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> cargaPuntual Acciones Variables </th>
                <td mat-cell *matCellDef="let element" align="right"> {{element.cargaPuntualAV | number:'0.2-2'}} </td>
            </ng-container>

            <ng-container matColumnDef="distanciaApoyo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> distancia de Apoyo </th>
                <td mat-cell *matCellDef="let element" align="right"> {{element.distanciaApoyo | number:'0.2-2'}} </td>
            </ng-container>
        -->
            <ng-container matColumnDef="imprimirCalculos">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> Imp.Cálculos </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.imprimirCalculos}} </td>
            </ng-container>

            <ng-container matColumnDef="calculos" class="center">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cálculos OK </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.calculos}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="muestraKitEnOferta(row)"
                [ngClass]="{'row-highlighted': kitUnaOferta.idProducto == row.idProducto && kitUnaOferta.idSituacion == row.idSituacion}">
            </tr>
        </table>

        <mat-paginator *ngIf="kitsUnaOferta.length>0" [pageSizeOptions]="[20]" showFirstLastButtons></mat-paginator>
        <div class="noHayNadaEnTabla" *ngIf="kitsUnaOferta.length == 0">No hay Kits</div>

    </div>

    <form #formulario="ngForm" (ngSubmit)="guardarKit(formulario)"
        class="form-derecho row-gap-15 flex-35-responsive pt-10">
        <app-autocomplete [disabled]=" !!idClienteAsignado || !esKitNuevo" class="autocomplete flex-100" name="producto"
            ngDefaultControl [idOpcionSeleccionada]="kitUnaOferta.idProducto"
            (cuandoSeSeleccioneOpcion)="kitUnaOferta.idProducto = $event" icon="settings"
            (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('producto')" label="Producto" [options]="productos">
        </app-autocomplete>
        <app-autocomplete [disabled]="!!idClienteAsignado" class="autocomplete flex-100" name="situacion"
            ngDefaultControl [idOpcionSeleccionada]="kitUnaOferta.idSituacion"
            (cuandoSeSeleccioneOpcion)="kitUnaOferta.idSituacion = $event" icon="settings"
            (cuandoSeCliqueEnIcono)="openDialogSituaciones()" label="Situación" [options]="situaciones">
        </app-autocomplete>
        <mat-form-field appearance="outline" class="flex-47-responsive">
            <mat-label>Cantidad</mat-label>
            <input matInput [disabled]="!!idClienteAsignado" type="number" [(ngModel)]="kitUnaOferta.cantidad"
                name="cantidad">
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-47-responsive">
            <mat-label>Precio Unitario</mat-label>
            <input matInput disabled [value]="obtenerDatosProducto(kitUnaOferta.idProducto).precioUnitario || ''">
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-47-responsive">
            <mat-label>Peso Unitario</mat-label>
            <input matInput disabled [value]="obtenerDatosProducto(kitUnaOferta.idProducto).pesoUnitario || ''">
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-47-responsive">
            <mat-label>Intereje</mat-label>
            <input matInput type="number" min="0" [(ngModel)]="kitUnaOferta.interaje" name="interaje">
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-47-responsive">
            <mat-label>Luz</mat-label>
            <input matInput type="number" min="0" [(ngModel)]="kitUnaOferta.luz" name="luz">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-47-responsive">
            <mat-label>Carga Repartida Acción Permanente</mat-label>
            <input matInput type="number" min="0" [(ngModel)]="kitUnaOferta.cargaRepartidaAP" name="cargaRepartidaAP">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-47-responsive">
            <mat-label>Carga Repartida Acción Variable</mat-label>
            <input matInput type="number" min="0" [(ngModel)]="kitUnaOferta.cargaRepartidaAV" name="cargaRepartidaAV">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-47-responsive">
            <mat-label>Carga Puntual Acción Permanente</mat-label>
            <input matInput type="number" min="0" [(ngModel)]="kitUnaOferta.cargaPuntualAP" name="cargaPuntualAP">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-47-responsive">
            <mat-label>Carga Puntual Acción Variable</mat-label>
            <input matInput type="number" min="0" [(ngModel)]="kitUnaOferta.cargaPuntualAV" name="cargaPuntualAV">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-47-responsive">
            <mat-label>distancia Apoyo</mat-label>
            <input matInput type="number" min="0" [(ngModel)]="kitUnaOferta.distanciaApoyo" name="distanciaApoyo">
        </mat-form-field>

        <div class="flex-row-wrap flex-50-responsive">
            <mat-checkbox class="flex-100" [(ngModel)]="kitUnaOferta.imprimirCalculos" name="imprimirCalculos">Imprimir
                Cálculos</mat-checkbox>
            <mat-checkbox class="flex-100" disabled [(ngModel)]="kitUnaOferta.calculos" name="calculos">Cálculos
                OK</mat-checkbox>
        </div>

        <div class="flex-row-wrap flex-100">
            <!--        
            <span class="flex-100">
                <button type="button" [disabled]="!!idClienteAsignado || !kitUnaOferta.idProducto || !kitUnaOferta.idSituacion || !kitUnaOferta.cantidad" mat-raised-button (click)="comprobarCalculos()">Comprobar Cálculos ... </button>
            </span>
     -->
            <span class="flex-50-responsive mt-10">
                <button type="button" mat-raised-button (click)="imprimirCalculos()">Imprimir Cálculos ... </button>
            </span>

            <span class="flex-50-responsive mt-10">
                <button [disabled]="!kitUnaOferta.idProducto || !kitUnaOferta.idSituacion || !kitUnaOferta.cantidad"
                    mat-raised-button type="submit">Guardar</button>
            </span>
            <span class="flex-50-responsive mt-10">
                <button mat-raised-button type="button" (click)="volverATablaUnaOferta()">Volver</button>
            </span>
        </div>

    </form>

</div>