import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FabricacionService } from '../../services/fabricacion.service';
import { type ChapaDesdeBD } from 'src/app/types/comprasChapa';
import { type ProdPlegable } from 'src/app/types/fabricacion';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRadioModule } from '@angular/material/radio';
import { TituloService } from '../../services/titulo.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule } from '@angular/forms';


@Component({
  selector: 'app-fabricacion',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDividerModule, MatIconModule, MatMenuModule, MatBadgeModule, MatRadioModule, FormsModule, MatProgressSpinnerModule],
  templateUrl: './fabricacion.component.html',
  styleUrl: './fabricacion.component.css'
})
export class FabricacionComponent {

  public cantidadAIncrementarUnidades = 1;

  constructor(private apiService: ApiService, private router: Router, private tituloService: TituloService, public fabricacionService: FabricacionService) { }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.fabricacionService.obtenerChapas();
    this.fabricacionService.obtenerProductosAPlegar();
  }

  public onChapaSelection(chapa: ChapaDesdeBD) {
    console.log('CHAPA SELECCIONADA PRIMERA VEZ: ', chapa.IdProducte);
    this.fabricacionService.chapaSeleccionada = chapa;
    this.fabricacionService.hayChapaSeleccionada = true;
    this.fabricacionService.unidades = 1;
  }

  public onProdSelection(prod: any) {
    console.log(prod);
    this.fabricacionService.prodSeleccionado = prod;
    this.fabricacionService.hayProdSeleccionado = true;
    this.fabricacionService.unidades = 1;
  }

  public resetSelection() {
    this.fabricacionService.chapaSeleccionada = {} as ChapaDesdeBD;
    this.fabricacionService.prodSeleccionado = {} as ProdPlegable;
    this.fabricacionService.hayChapaSeleccionada = false;
    this.fabricacionService.hayProdSeleccionado = false;
    this.fabricacionService.esPlegado = false;
    this.fabricacionService.unidades = 1;
  }

  public resetPlegado() {
    this.fabricacionService.prodSeleccionado = {} as ProdPlegable;
    this.fabricacionService.hayProdSeleccionado = false;
    this.fabricacionService.esPlegado = false;
    this.fabricacionService.hayChapaSeleccionada = false;
    this.fabricacionService.chapaSeleccionada = {} as ChapaDesdeBD;
    this.fabricacionService.esFabricacion = false;
    this.fabricacionService.productesEnFabricacio = [];
    this.fabricacionService.cantidadPlegables = 1;
    this.fabricacionService.unidades = 1;
  }

  public redirect() {
      this.fabricacionService.idChapa = this.fabricacionService.chapaSeleccionada.IdProducte;
      this.fabricacionService.cantidadDeChapas = this.fabricacionService.cloneVariable(this.fabricacionService.unidades);
      this.fabricacionService.unidades = 1;
      this.fabricacionService.esFabricacion = true;
      this.router.navigate(['generar-orden-fabricacion']);
      console.log(this.fabricacionService.idChapa, this.fabricacionService.cantidadDeChapas, this.fabricacionService.chapaSeleccionada);
  }

  public handleVolver() {
    if (this.fabricacionService.hayChapaSeleccionada) {
      this.redirect();
    } else {
      this.resetPlegado();
      this.router.navigate(['taller']);
    }

  }

  public anadirPlegable() {
    this.fabricacionService.idPlegable = this.fabricacionService.prodSeleccionado.IdProducte;
    this.fabricacionService.cantidadPlegables = this.fabricacionService.cloneVariable(this.fabricacionService.unidades);
    this.fabricacionService.prodSeleccionado.Punzonado = this.fabricacionService.prodSeleccionado.Punzonado - this.fabricacionService.unidades;
    this.fabricacionService.anadirProducto();
    console.log('PRODUCTOS EN FABRICACIO', this.fabricacionService.productesEnFabricacio);
    this.fabricacionService.unidades = 1;
  }

  public incrementarUnidades() {
    this.fabricacionService.unidades += this.cantidadAIncrementarUnidades;
  }

  public decrementarUnidades() {
    this.fabricacionService.unidades -= this.cantidadAIncrementarUnidades;
    if(this.fabricacionService.unidades < 0) this.fabricacionService.unidades = 1;
  }

  public disableDecrementar() {
    if (this.fabricacionService.unidades <= 1) return true;
    return false;
  }

  public disableIncrementar() {
    if (this.fabricacionService.hayChapaSeleccionada && this.fabricacionService.unidades >= this.fabricacionService.chapaSeleccionada.StockActual) return true;
    if (this.fabricacionService.hayProdSeleccionado &&this.fabricacionService.unidades >= this.fabricacionService.prodSeleccionado.Punzonado) return true;
    if (!this.fabricacionService.hayChapaSeleccionada && !this.fabricacionService.hayProdSeleccionado) return true;
    return false;
  }

  public disableUnidades() {
    if (this.disableDecrementar() || this.disableIncrementar()) return true;
    return false;
  }

  public selectFabricacion() {
    
    this.fabricacionService.esFabricacion = true;
    this.fabricacionService.esPlegado = false;

    const hayProdPunzonado = this.fabricacionService.productesEnFabricacio.length > 0 && this.fabricacionService.productesEnFabricacio.some((prod: any) => prod['status'] === this.fabricacionService.fabStatus.PUNZONADO);

    if (this.fabricacionService.hayChapaSeleccionada && this.fabricacionService.chapaSeleccionada && hayProdPunzonado) {
      this.router.navigate(['generar-orden-fabricacion']);
      return;
    }

    this.router.navigate(['taller/fabricacion']);
    this.fabricacionService.hayChapaSeleccionada = false;
    this.fabricacionService.chapaSeleccionada = {} as ChapaDesdeBD;
  }

  public selectPlegado() {
    this.fabricacionService.esFabricacion = false;
    this.fabricacionService.esPlegado = true;
    this.fabricacionService.prodSeleccionado = {} as ProdPlegable;
    this.fabricacionService.hayProdSeleccionado = false;
    this.router.navigate(['taller/plegado']);
  }

  public volverAFabricacion() {
    this.router.navigate(['generar-orden-fabricacion']);
  }

  public resetProceso() {
    this.fabricacionService.esFabricacion = false;
    this.router.navigate(['taller']);
  }
}
