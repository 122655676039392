<form class="container flex-row-wrap" #ComprasDeChapaComponent>

  <!-- Agregar criterios de filtrado -->
  <!-- <div class="layout-filtro flex flex-100">
  </div> -->

  <div class="layout-tabla flex-layout flex-65-responsive">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 standar-table-mecanoviga"
      aria-label="Compras de chapa">

      <ng-container matColumnDef="Buttons">
        <th mat-header-cell *matHeaderCellDef style="width: 15px;">
          <button class="button-create" mat-button type="button" disableRipple (click)="esCompraNueva()">
            <mat-icon>add</mat-icon>
          </button>
        </th>

        <td mat-cell *matCellDef="let compra" style="width: 15px;">
          @if (compra && compra.Comandas === 0) {
          <button mat-button type="button" disableRipple (click)="openDialog(compra.IdCompra)">
            <mat-icon>deletes</mat-icon>
          </button>
          }
        </td>
      </ng-container>

      <!-- Column definitions -->
      <ng-container matColumnDef="Tipus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
        <td mat-cell *matCellDef="let compra"> {{compra.Tipus}} </td>
      </ng-container>

      <ng-container matColumnDef="DataCompra">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
        <td mat-cell *matCellDef="let compra"> {{compra.DataCompra | date: 'dd/MM/yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="Preu">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio </th>
        <td mat-cell *matCellDef="let compra"> <span *ngIf="compra.Preu !== null">{{compra.Preu}}€/T</span> </td>
      </ng-container>

      <ng-container matColumnDef="Estat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
        <td mat-cell *matCellDef="let compra"> <b>{{compra.Estat}}</b> </td>
      </ng-container>

      <!-- Row definitions -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="seleccionarUnaCompra(row)"
        [ngClass]="definirColorRow(row.IdCompra, row.Estat, compraChapa.IdCompra)"></tr>

    </table>

    @if (listaComprasChapa.length === 0) {
    <div class="noHayNadaEnTabla">No hay compras de chapa</div>
    }

    <mat-paginator [pageSizeOptions]="[100]" showFirstLastButtons
      [ngClass]="{'hidden':true, 'no-hidden': listaComprasChapa.length > 0}" aria-label="Seleccionar página de compras">
    </mat-paginator>

  </div>

  <form #formulario="ngForm" (submit)="actualizarCompra()" class="form-derecho flex-35-responsive row-gap-15">
    <mat-form-field class="flex-70-responsive" appearance="outline">
      <mat-label> Tipo </mat-label>
      <input matInput name="tipo" type="text" [(ngModel)]="compraChapa.Tipus" [disabled]="!hayCompraSeleccionada" required>
    </mat-form-field>

    <mat-form-field appearance="outline" class="flex-70-responsive">
      <mat-label>
        Fecha Compra
      </mat-label>
      <input matInput [matDatepicker]="picker" name="fechaCompra" [(ngModel)]="compraChapa.DataCompra"
        [disabled]="!crearCompraNueva" required>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="flex-70-responsive" appearance="outline">
      <mat-label> Precio €/T </mat-label>
      <input matInput name="precio" type="number" [(ngModel)]="compraChapa.Preu" min="0" [disabled]="!hayCompraSeleccionada">
    </mat-form-field>

    <mat-form-field class="flex-70-responsive" appearance="outline">
      <mat-label>Estado</mat-label>
      <mat-select name="estado" [ngModel]="mapper[compraChapa.Estat]" disabled required>
        <mat-option [value]="mapper.Entregado">Entregado</mat-option>
        <mat-option [value]="mapper.Parcial">Parcial</mat-option>
        <mat-option [value]="mapper.Pendiente">Pendiente</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="flex-46-responsive mt-30">
      <mat-label>
        Imprimir albaranes desde
      </mat-label>
      <input matInput [matDatepicker]="picker2" name="fechaAlbaranesDesde" [(ngModel)]="fechaAlbaranesDesde" required>
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" class="flex-46-responsive mt-30">
      <mat-label>
        Imprimir albaranes hasta
      </mat-label>
      <input matInput [matDatepicker]="picker3" name="fechaAlbaranesHasta" [(ngModel)]="fechaAlbaranesHasta" required>
      <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
      <mat-datepicker #picker3></mat-datepicker>
    </mat-form-field>

    <button mat-raised-button type="button" class="flex-95 mt-10" (click)="imprimirAlbaranes()">
      Imprimir albaranes
    </button>

    <button mat-raised-button type="button" class="flex-95 mt-30" [disabled]="!hayCompraSeleccionada || crearCompraNueva" (click)="imprimirCompra()">
      Imprimir compra
    </button>

    <span class="flex-48-responsive mt-10">
      <button [disabled]="crearCompraNueva || !hayCompraSeleccionada" type="button" mat-raised-button
        (click)="irADetallesCompra()">Detalles</button>
    </span>

    <span class="flex-48-responsive mt-10">
      <button class="button__buscar" type="submit" mat-raised-button [disabled]="!hayCompraSeleccionada || compraChapa.Preu < 0">Guardar</button>
    </span>
  </form>

</form>