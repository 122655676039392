<h1 mat-dialog-title>Stock de zincador</h1>

<mat-dialog-content *ngIf="loaded" class="mantenimiento">
    <div class="tabla flex-layout flex-70-responsive">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga"
            aria-label="Stock En Produccion" style="min-width: 100% !important;">
            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button
                        (click)="openDeleteDialog(element.IdProdAZinc)"><mat-icon>deletes</mat-icon></button>
                </td>
            </ng-container>

            <ng-container matColumnDef='Producto'>
                <th mat-header-cell *matHeaderCellDef> Producto </th>
                <td mat-cell *matCellDef="let producto"> {{producto.nombreProducto}} </td>
            </ng-container>

            <ng-container matColumnDef='Fabricado'>
                <th mat-header-cell *matHeaderCellDef> Stock actual </th>
                <td mat-cell *matCellDef="let producto" align="left"> <b>{{producto.StockActual}}</b> </td>
            </ng-container>

            <ng-container matColumnDef='Punzonado'>
                <th mat-header-cell *matHeaderCellDef> Punzonado </th>
                <td mat-cell *matCellDef="let producto" align="left"> {{producto.Punzonado}} </td>
            </ng-container>

            <ng-container matColumnDef='PunzonadoYPlegado'>
                <th mat-header-cell *matHeaderCellDef> Punzonado y plegado </th>
                <td mat-cell *matCellDef="let producto" align="left"> {{producto.StockActual - (producto.Zincado +
                    producto.EnZincador + producto.Punzonado)}} </td>
            </ng-container>

            <ng-container matColumnDef='Zincador'>
                <th mat-header-cell *matHeaderCellDef> En zincador </th>
                <td mat-cell *matCellDef="let producto" align="left"> {{producto.EnZincador}} </td>
            </ng-container>

            <ng-container matColumnDef='Zincado'>
                <th mat-header-cell *matHeaderCellDef> Zincado </th>
                <td mat-cell *matCellDef="let producto" align="left"> {{producto.Zincado}} </td>
            </ng-container>

            <ng-container matColumnDef='Zincador al cliente'>
                <th mat-header-cell *matHeaderCellDef> Zincador al cliente </th>
                <td mat-cell *matCellDef="let producto" align="left">
                    <span style="color: #ffb81d; font-weight: 600;">{{producto.ZincadorAClient}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef='Albaran zincador'>
                <th mat-header-cell *matHeaderCellDef> Albaran zincador </th>
                <td mat-cell *matCellDef="let producto" align="left"> {{producto.AlbaraZincador}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                [ngClass]="{'row-highlighted': producto.idProducto === row.idProducto}"
                (click)="seleccionarProducto(row)"></tr>

        </table>

        @if (productosAlZincador.length === 0) {
        <div class="noHayNadaEnTabla">No hay productos para enviar al zincador</div>
        }
    </div>

    <form class="form-derecho row-gap-10 justify-between flex-30-responsive" #formulario="ngForm"
        *ngIf="loadedAutocomplete" (submit)="guardarStock()">

        @if (hayItemSeleccionado) {
        <mat-form-field class="flex-100" appearance="outline">
            <mat-label>Producto</mat-label>

            <input name="Producto" matInput type="text" aria-label="Producto" [ngModel]="producto.nombreProducto"
                disabled>
        </mat-form-field>
        } @else {
        <mat-form-field class="flex-100" appearance="outline">
            <mat-label>Producto</mat-label>

            <input type="text" name="Producto" placeholder='Seleccionar producto' aria-label="Producto" matInput
                [formControl]="control" [matAutocomplete]="auto">

            <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption (optionSelected)="onProductSelected($event)"
                [displayWith]="displayFn">
                @for (option of filteredOptions | async; track option) {
                <mat-option [value]="option">{{option.nombreProducto}}</mat-option>
                }
            </mat-autocomplete>
            <button class="limpiar" mat-button *ngIf="this.hayProductoSeleccionado" matSuffix
                (click)="this.resetProduct()"><mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        }

        <mat-form-field appearance="outline" class="flex-75-responsive">
            <mat-label> Stock actual </mat-label>
            <input #numberInput name="Fabricado" matInput type="number" [ngModel]="producto.StockActual" disabled
                min="0" (wheel)="handleWheelEvent($event)">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-75-responsive">
            <mat-label> Punzonado </mat-label>
            <input #numberInput name="Punzonado" matInput type="number"
                [value]="producto.Punzonado" disabled min="0"
                (wheel)="handleWheelEvent($event)">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-75-responsive">
            <mat-label> Punzonado y plegado </mat-label>
            <input #numberInput name="PunzonadoYPlegado" matInput type="number"
                [value]="producto.StockActual - (producto.Zincado + producto.EnZincador + producto.Punzonado)" disabled min="0"
                (wheel)="handleWheelEvent($event)">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-75-responsive">
            <mat-label> En zincador </mat-label>
            <input name="Zincador" matInput type="number" [(ngModel)]="producto.EnZincador" disabled min="0"
                [max]="producto.StockActual">
        </mat-form-field>
        
        <mat-form-field appearance="outline" class="flex-75-responsive">
            <mat-label> Zincado </mat-label>
            <input #numberInput name="Zincado" matInput type="number" [(ngModel)]="producto.Zincado" disabled min="0"
                (wheel)="handleWheelEvent($event)">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-48-responsive mt-20">
            <mat-label> Enviar a zincador </mat-label>
            <input #numberInput name="Enviar" matInput type="number"
                [disabled]="!hayProductoSeleccionado && !hayItemSeleccionado"
                [(ngModel)]="cantidadAZincador"
                [max]="producto.StockActual - (producto.Zincado + producto.EnZincador)"
                (wheel)="handleWheelEvent($event)" (change)="onCantidadAZincadorChange($event)">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-48-responsive mt-20">
            <mat-label> Zincador al cliente </mat-label>
            <input #numberInput name="ZincadorAlCliente" matInput type="number"
                [disabled]="!hayProductoSeleccionado && !hayItemSeleccionado"
                [(ngModel)]="this.cantidadZincadorACliente" min="0" (wheel)="handleWheelEvent($event)"
                (change)="onCantidadZincadorAClienteChange($event)"
                [max]="this.producto.EnZincador - this.cantidadDevueltas!">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-48-responsive mt-20">
            <mat-label> Devueltas </mat-label>
            <input #numberInput name="Devueltas" matInput type="number"
                [disabled]="!hayProductoSeleccionado && !hayItemSeleccionado"
                [(ngModel)]="cantidadDevueltas" min="0" (wheel)="handleWheelEvent($event)"
                [max]="this.producto.EnZincador - this.cantidadZincadorACliente!">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-48-responsive mt-20">
            <mat-label> Nº Albaran </mat-label>
            <input name="Nº Albaran" matInput type="text" [(ngModel)]="albaraZincador">
        </mat-form-field>

        <div class="LimpiarGuardar flex-row-wrap justify-between flex-100">

            <button class="flex-48-responsive mt-10" mat-raised-button type="submit"
                [disabled]="this.puedeGuardar() && producto.EnZincador! >= 0">GUARDAR</button>
            <button class="flex-48-responsive mt-10" mat-raised-button type="button"
                (click)="resetProduct()">LIMPIAR</button>

            <span class="stock flex-100" style="height: 40px !important;">
                <mat-form-field appearance="outline" class="flex-100">
                    <mat-label>Elegir Proveedor</mat-label>
                    <mat-select [(ngModel)]="opcionProveedor" name="opcionProveedor">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let opcion of opcionesProveedor" [value]="opcion.idProveedor">
                            {{opcion.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </span>

            <button mat-raised-button type="button" class="flex-100 mt-30" (click)="ImprimirAlbaranZincador()"
                [disabled]="productosAlZincador.length === 0 || !opcionProveedor || this.noHayProductosParaEnviar()">
                Albarán Zincador
            </button>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="actions-padding">
    <button color="primary" mat-button mat-dialog-close mat-stroked-button>
        <mat-icon>close</mat-icon> Cerrar
    </button>
</mat-dialog-actions>