<h2 class="h2titulo flex flex-65-responsive justify-center">Líneas de Detalle del Albarán de Salida {{nAlbaran}} de la
    Oferta Nº {{nOferta}}</h2> <br>

<div class="container flex-row-wrap">

    <div class="layout-tabla flex-layout flex-65-responsive">

        <table mat-table [dataSource]="dataSource" matSort
            class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">

            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>
                    <button *ngIf="estado" class="button-create" mat-icon-button type="button"
                        (click)="esDetalleProductoNuevo()">
                        <mat-icon>create_new_folder</mat-icon>
                    </button>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="estado" mat-icon-button type="button"
                        (click)="dialogEliminarDetalleProducto(element)">
                        <mat-icon>deletes</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="nombreProducto">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.nombreProducto}} </td>
            </ng-container>

            <ng-container matColumnDef="cantidadOferta">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad Oferta</th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.cantidadOferta}} </td>
            </ng-container>

            <ng-container matColumnDef="cantidadEnviada">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad Enviada</th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.cantidadEnviada}} </td>
            </ng-container>

            <ng-container matColumnDef="cantidadDevuelta">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad Devuelta</th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.cantidadDevuelta}} </td>
            </ng-container>

            <ng-container matColumnDef="cantidadAlbaran">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad Albarán</th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.cantidadAlbaran}} </td>
            </ng-container>

            <ng-container matColumnDef="esKit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ¿ Kit ?</th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.esKit}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="muestradetalleProducto(row)"
                [ngClass]="{'row-highlighted': detalleUnProducto.idProducto == row.idProducto}"></tr>
        </table>
        <mat-paginator [ngClass]="{'hidden':true, 'no-hidden': listaDetalleProductos.length > 0}"
            [pageSizeOptions]="[20]" showFirstLastButtons></mat-paginator>
        <div class="noHayNadaEnTabla" *ngIf="listaDetalleProductos.length == 0">No hay productos</div>

    </div>

    <form #formulario="ngForm" (ngSubmit)="guardarDetalleProducto(formulario)"
        class="form-derecho row-gap-15 flex-35-responsive">

        <mat-form-field class="flex-55-responsive" appearance="outline">
            <mat-label> Nº Oferta</mat-label>
            <input disabled [(ngModel)]="nOferta" name="nOferta" matInput type="text">
        </mat-form-field>

        <app-autocomplete *ngIf="booleanEsKit" class="autocomplete flex-80-responsive" name="productosKits"
            ngDefaultControl [idOpcionSeleccionada]="detalleUnProducto.idProducto"
            [disabled]="!crearProductoNuevo || !estado"
            (cuandoSeSeleccioneOpcion)="detalleUnProducto.idProducto = $event" label="Producto"
            [options]="productosKits">
        </app-autocomplete>

        <app-autocomplete *ngIf="!booleanEsKit" class="autocomplete flex-80-responsive" name="productosOtros"
            ngDefaultControl [idOpcionSeleccionada]="detalleUnProducto.idProducto"
            [disabled]="!crearProductoNuevo || !estado"
            (cuandoSeSeleccioneOpcion)="detalleUnProducto.idProducto = $event" label="Producto"
            [options]="productosOtros">
        </app-autocomplete>

        <mat-checkbox class="checkbox flex-20-responsive" [(ngModel)]="booleanEsKit" name="esKit">Kit</mat-checkbox>

        <mat-form-field class="flex-55-responsive" appearance="outline">
            <mat-label> Cantidad Albarán</mat-label>
            <input [disabled]="!detalleUnProducto.idProducto || !estado" [(ngModel)]="detalleUnProducto.cantidadAlbaran"
                name="cantidadAlbaran" matInput type="number">
        </mat-form-field>

        <span class="flex-100">
            <button *ngIf="booleanEsKit" [disabled]="!detalleUnProducto.idProducto" class="button__buscar" type="button"
                (click)="muestraComponents(); $event.stopPropagation()" mat-raised-button>Ver Componentes</button>
        </span>

        <h3 class="flex-100">------------ Cantidades Pedido -----------</h3>

        <mat-form-field class="flex-55-responsive" appearance="outline">
            <mat-label> Cantidad Oferta</mat-label>
            <input disabled [(ngModel)]="detalleUnProducto.cantidadOferta" name="cantidadOferta" matInput type="number">
        </mat-form-field>

        <mat-form-field class="flex-55-responsive" appearance="outline">
            <mat-label> Cantidad Enviada</mat-label>
            <input disabled [(ngModel)]="detalleUnProducto.cantidadEnviada" name="cantidadEnviada" matInput
                type="number">
        </mat-form-field>

        <mat-form-field class="flex-55-responsive" appearance="outline">
            <mat-label> Cantidad Devuelta</mat-label>
            <input disabled [(ngModel)]="detalleUnProducto.cantidadDevuelta" name="cantidadDevuelta" matInput
                type="number">
        </mat-form-field>



        <span class="flex-100">
            <button [disabled]="listaDetalleProductos.length>0 || !estado" class="button__buscar" type="button"
                (click)="generarLineasPendientes()" mat-raised-button>Generar Líneas Pendientes Enviar</button>
        </span>

        <span class="flex-48-responsive">
            <button [disabled]="!detalleUnProducto.idProducto || !detalleUnProducto.cantidadAlbaran || !estado"
                class="button__buscar" type="submit" mat-raised-button>Guardar</button>
        </span>

        <span class="flex-48-responsive">
            <button class="button__buscar" type="button" (click)="volverAAlbaranesSalida()"
                mat-raised-button>Volver</button>
        </span>

    </form>

</div>