<form class="container flex-row-wrap" #DetallesCompraChapaComponent>

    <!-- Aplicar criterios de filtrado -->
    <!-- <div class="layout-filtro flex flex-100">
    </div> -->

    <div class="layout-tabla flex-layout flex-65-responsive">
        @if(comandaChapaCompleta.length > 0) {
        <div class="compra-table-header" style="position: sticky; top: 0;">
            <span>Id compra: {{comandaChapaCompleta[0].IdCompra}}</span>
            <span>Tipo: {{comandaChapaCompleta[0].Tipus}}</span>
            <span>Fecha de compra: {{comandaChapaCompleta[0].DataCompra | date: 'dd/MM/yyyy'}}</span>
            <span>Precio: {{comandaChapaCompleta[0].Preu}}€/T</span>
            <button class="add-button" (click)="openDialog()" [disabled]="crearComandaNueva || fueEntregado">Añadir
                albarán</button>
        </div>
        }

        <div class="layout-tabla flex-layout w-100">

            <!-- Table -->
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 standar-table-mecanoviga"
                aria-label="Compras de chapa" style="min-width: 100% !important;">

                <ng-container matColumnDef="Buttons">
                    <th mat-header-cell *matHeaderCellDef style="width: 15px;">
                        <button class="button-create" mat-button type="button" disableRipple (click)="esComandaNueva()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </th>

                    <td mat-cell *matCellDef="let comanda" style="width: 15px;">
                        @if (comanda && comanda.DatosAlbaran.length === 0) {
                        <button mat-button type="button" disableRipple (click)="openDeleteDialog(comanda.IdComanda)">
                            <mat-icon>deletes</mat-icon>
                        </button>
                        }
                    </td>
                </ng-container>

                <!-- Column definitions -->
                <ng-container matColumnDef='DescProducte'>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Chapa </th>
                    <td mat-cell *matCellDef="let comanda"> {{comanda.DescProducte}}</td>
                </ng-container>

                <ng-container matColumnDef='NumPeces'>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> No. Piezas </th>
                    <td mat-cell *matCellDef="let comanda"> {{comanda.TotalPecesEntregats ??
                        0}}<b>/{{comanda.NumPeces}}</b>
                    </td>
                </ng-container>

                <ng-container matColumnDef='Estat'>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                    <td mat-cell *matCellDef="let comanda">
                        {{comanda.Estat}}
                    </td>
                </ng-container>

                <ng-container matColumnDef='PesAlta'>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Peso (kg) </th>
                    <td mat-cell *matCellDef="let comanda"> {{comanda.TotalPesLliurat ?? 0}}<b>/{{comanda.PesAlta}}</b>
                    </td>
                </ng-container>

                <ng-container matColumnDef='Albarans'>
                    <th mat-header-cell *matHeaderCellDef> Albaranes </th>
                    <td mat-cell *matCellDef="let comanda">
                        <button style="width: fit-content;" mat-button [matMenuTriggerFor]="menu"
                            [disabled]="comanda.DatosAlbaran.length === 0">
                            Ver albaranes
                        </button>
                        <mat-menu #menu="matMenu" class="pt-10 pb-10">
                            @for (albaran of comanda.DatosAlbaran; track albaran.Id; let index = $index) {
                            <button mat-menu-item class="w-100 no-pointer">
                                <div class="flex flex-col">
                                    <div>
                                        <span>
                                            Nº <b>{{albaran.NumAlbara}}</b> -
                                            Fecha: {{albaran.DataAlbara | date:'dd/MM/yyyy'}}
                                        </span>
                                    </div>
                                    <span>No Piezas entregadas: <b>{{albaran.NumPecesEntregats}}</b></span>
                                    <span>Peso entregado: <b>{{albaran.PesLliurat}}</b></span>
                                    @if (index < comanda.DatosAlbaran.length - 1) { <mat-divider
                                        class="w-100 mt-10 mb-10">
                                        </mat-divider>
                                        }

                                </div>
                            </button>
                            }
                        </mat-menu>
                    </td>
                </ng-container>

                <!-- Row definitions -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" style="top: -60px;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="seleccionarComanda(row)"
                    class="pointer" [ngClass]="definirColorRow(row.IdComanda, row.Estat, comandaChapa.IdComanda)">
                </tr>

            </table>

            @if (comandaChapaCompleta.length === 0) {
            <div class="noHayNadaEnTabla">No hay comandas de chapa</div>
            }

            <mat-paginator [pageSizeOptions]="[20]" showFirstLastButtons
                [ngClass]="{'hidden':true, 'no-hidden': comandaChapaCompleta.length > 0}"
                aria-label="Seleccionar página de comandas">
            </mat-paginator>

        </div>

    </div>

    <!-- Form compra -->
    <form #formulario="ngForm"
        (ngSubmit)="crearComandaNueva ? anadirComandaChapa() : modificarEstadoCompraChapaDetalle()"
        class="form-derecho row-gap-15 justify-between flex-35-responsive">

        @if (this.hayComandaSeleccionada) {
        <mat-form-field class="flex-100" appearance="outline">
            <mat-label>Chapa</mat-label>

            <input type="text" name="chapa" placeholder="Chapa" aria-label="Chapa" matInput
                [ngModel]="this.comandaChapa.DescProducte" disabled>

        </mat-form-field>
        } @else {
        <mat-form-field class="flex-100" appearance="outline">
            <mat-label>Chapa</mat-label>

            <input type="text" name="chapa" placeholder="Chapa" aria-label="Chapa" matInput [formControl]="control"
                [matAutocomplete]="auto" required>


            <mat-autocomplete [autoActiveFirstOption]="false" #auto="matAutocomplete"
                (optionSelected)="onChapaSelected($event)" [displayWith]="displayFn" requireSelection>
                @for (option of filteredOptions | async; track option) {
                <mat-option [value]="option">{{option.DescProducte}}</mat-option>
                }
            </mat-autocomplete>

            @if (this.hayChapaSeleccionada) {
            <button class="limpiar" matSuffix mat-icon-button aria-label="Clear" (click)="resetCompraChapaDetalle()">
                <mat-icon>close</mat-icon>
            </button>
            }
        </mat-form-field>
        }
        
        <mat-form-field class="flex-60-responsive" appearance="outline">
            <mat-label> Nº piezas </mat-label>
            <input matInput name="NumPeces" type="number" [(ngModel)]="comandaChapa.NumPeces" min="0"
                [disabled]="(!chapaSeleccionada.IdProducte && !comandaChapa.Estat) || estadoComanda.ENTREGADO == comandaChapa.Estat || estadoComanda.PARCIAL == comandaChapa.Estat" (input)="onNumPiecesChange($event)"
                required>
        </mat-form-field>

        <mat-form-field class="flex-60-responsive" appearance="outline">
            <mat-label>Estado</mat-label>
            <mat-select name="Estat" [ngModel]="mapper[comandaChapa.Estat]" required
                (selectionChange)="onEstatChange($event)" [disabled]="!crearComandaNueva && !hayComandaSeleccionada">
                <mat-option [value]="mapper[estadoComanda.ENTREGADO]">Entregado</mat-option>
                <mat-option [value]="mapper[estadoComanda.PARCIAL]">Parcial</mat-option>
                <mat-option [value]="mapper[estadoComanda.PENDIENTE]">Pendiente</mat-option>
            </mat-select>

        </mat-form-field>

        <mat-form-field class="flex-60-responsive" appearance="outline">
            <mat-label> Peso (kg) </mat-label>
            <input matInput name="Pes" type="number" [ngModel]="comandaChapa.PesAlta" min="0" disabled required>
        </mat-form-field>

        <div class="flex flex-100 justify-around mt-10">
            <button class=" flex-48-responsive" type="submit" mat-raised-button
                [disabled]="!crearComandaNueva && !hayComandaSeleccionada">
                Guardar</button>
            <button class="flex-48-responsive" type="button" mat-raised-button
                (click)="volverAComprasChapa()">Volver</button>
        </div>
    </form>

</form>