import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatOption, MatSelect, MatSelectChange } from '@angular/material/select';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { estadoCompra, estadoMapper, estadoMapperInverso, valoresDatosAlbaran } from 'src/app/constants/comprasChapa.constant';
import { DialogAgregarAlbaranCompraChapaComponent } from 'src/app/dialogs/dialog-agregar-albaran-compra-chapa/dialog-agregar-albaran-compra-chapa.component';
import { ApiService } from 'src/app/services/api.service';
import { ChapaDesdeBD, ComandaChapaDesdeBD, ComandaChapaNueva } from 'src/app/types/comprasChapa';
import { ComprasDeChapaComponent } from '../compras-de-chapa.component';
import { MatDividerModule } from '@angular/material/divider';
import { Observable } from 'rxjs';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, startWith } from 'rxjs/operators';
import { DialogPopUpComponent } from 'src/app/dialogs/dialog-pop-up/dialog-pop-up.component';
import { TituloService } from 'src/app/services/titulo.service';

@Component({
  selector: 'app-detalles-compra-chapa',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginator,
    MatPaginatorModule,
    MatSort,
    MatSortModule,
    MatLabel,
    MatDatepickerModule,
    MatDatepickerToggle,
    MatSelect,
    MatOption,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatDividerModule,
    MatAutocompleteModule
  ],
  templateUrl: './detalles-compra-chapa.component.html',
  styleUrl: './detalles-compra-chapa.component.css'
})
export class DetallesCompraChapaComponent implements AfterViewInit {
  public dataSource!: MatTableDataSource<ComandaChapaDesdeBD>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public paramsUrl: Params = {};

  public comandaChapaCompleta: ComandaChapaDesdeBD[] = [];
  public chapas: any[] = []; //----------------------------------------------------> Cambiar por el tipo correcto

  public chapaSeleccionada: ChapaDesdeBD = {} as ChapaDesdeBD;
  public comandaChapa: ComandaChapaDesdeBD = {} as ComandaChapaDesdeBD;
  public comandaChapaNueva: ComandaChapaNueva = {} as ComandaChapaNueva;
  public idCompra = 0;

  public hayComandaSeleccionada = false;
  public hayChapaSeleccionada = false;
  public crearComandaNueva = false;
  public fueEntregado = true;

  public estadoComanda = estadoCompra;
  public mapper = estadoMapper;
  public mapperInverso = estadoMapperInverso;

  public nuevoEstado: number | null = null;
  public valoresAlbaran = valoresDatosAlbaran;

  control = new FormControl({ value: this.comandaChapa.IdProducte, disabled: true }, Validators.required);

  filteredOptions: Observable<any[]> = new Observable();

  headerCompra: string[] = ['IdCompra', 'Tipus', 'DataCompra', 'Preu'];
  displayedColumns: string[] = [
    'Buttons',
    'DescProducte',
    'NumPeces',
    'Estat',
    'PesAlta',
    'Albarans'
  ];

  constructor(
    private apiService: ApiService,
    private router: Router,
    private tituloService: TituloService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
  ) {
    this.dataSource = new MatTableDataSource(this.comandaChapaCompleta);
  }

  ngOnInit() {
    this.obtenerCompra();
    this.obtenerChapas();
    this.tituloService.enviarTitulo();

    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value?.toString() ?? '')),
    );
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.chapas.filter(chapa => chapa?.DescProducte?.toLowerCase().includes(filterValue));
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogAgregarAlbaranCompraChapaComponent, {
      data: this.comandaChapa,
      autoFocus: false
    });


    this.router.navigate(['detalles-compra-chapa'],
      {
        queryParams: {
          idCompra: this.comandaChapa.IdCompra,
          idComanda: this.comandaChapa.IdComanda
        }
      }
    );

    console.log('COMANDA CHAPA >>>>>>>>', this.comandaChapa);

    dialogRef.afterClosed().subscribe(() => {
      this.obtenerCompra();
      this.crearComandaNueva = false;
      this.volverAComprasChapaDetalle();
      this.resetCompraChapaDetalle();
    });
  }

  private obtenerChapas() {
    this.apiService.obtenerChapas().subscribe((data) => {
      this.chapas = data.response;

      console.log('LISTA DE CHAPAS', data.response);
    });
  }

  private obtenerCompra() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.idCompra = Number(params.idCompra);
      this.apiService.obtenerCompraChapaDetalle(this.idCompra).subscribe((data) => {
        this.comandaChapaCompleta = data.response;
        console.log('LISTA DE COMANDAS:', this.comandaChapaCompleta);
        this.dataSource = new MatTableDataSource(this.comandaChapaCompleta);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    });
  }

  public definirColorRow(rowIdComanda: number, rowEstat: string, comandaChapaIdComanda?: number): any {
    return ComprasDeChapaComponent.colorRow(rowIdComanda, rowEstat, comandaChapaIdComanda);
  }

  public seleccionarComanda(comanda: ComandaChapaDesdeBD) {
    this.comandaChapa = comanda;
    this.fueEntregado = this.comandaChapa.Estat === this.estadoComanda.ENTREGADO
    this.crearComandaNueva = false;
    this.hayComandaSeleccionada = true;
    console.log(this.comandaChapa);
  }

  public volverAComprasChapa() {
    this.router.navigate(['compras-de-chapa'],
      {
        queryParams: this.paramsUrl
      }
    );
  }

  private volverAComprasChapaDetalle() {
    this.router.navigate(['detalles-compra-chapa'],
      {
        queryParams: {
          idCompra: this.comandaChapa.IdCompra
        }
      }
    );
  }

  public modificarEstadoCompraChapaDetalle() {
    const idCompra = this.comandaChapa.IdCompra;
    const idComanda = this.comandaChapa.IdComanda;
    const idEstado = this.nuevoEstado! ? this.nuevoEstado : this.mapper[this.comandaChapa.Estat];
    const units = this.comandaChapa.NumPeces;
    const pesAlta = this.comandaChapa.PesAlta;
    console.log('ESTADO A MODIFICAR >>>>>>>>', idEstado, this.comandaChapa.Estat, this.nuevoEstado);
    this.apiService.modificarEstadoCompraChapaDetalle(idCompra, idComanda, idEstado, units, pesAlta)
      .subscribe(() => {
        this.obtenerCompra();
        this.resetCompraChapaDetalle();
      });
  }

  public esComandaNueva() {
    this.crearComandaNueva = true;
    this.resetCompraChapaDetalle();
    this.control.enable();
    this.comandaChapa.Estat = this.estadoComanda.PENDIENTE;
    this.comandaChapaNueva.PesAlta = 0;
  }

  public resetCompraChapaDetalle() {
    this.comandaChapa = {} as ComandaChapaDesdeBD;
    this.hayComandaSeleccionada = false;
    this.hayChapaSeleccionada = false;
    this.fueEntregado = true;
    this.control.reset();
    this.control.disable();
    this.nuevoEstado = null;
  }

  public onEstatChange(newValue: MatSelectChange) {
    this.nuevoEstado = newValue.value;
  }

  public esString(valor: any): boolean {
    return typeof valor === 'string';
  }

  public onChapaSelected(event: MatAutocompleteSelectedEvent): void {
    this.hayChapaSeleccionada = true;
    this.chapaSeleccionada = event.option.value;
  }

  displayFn(chapa: ChapaDesdeBD): string {
    return chapa?.DescProducte ?? '';
  }

  public onNumPiecesChange(newValue: any) {
    console.log('NUM PIECES >>>>>>>>', newValue.target.value, this.chapaSeleccionada.Pes, this.comandaChapa);
    let pes = 0;
    if(this.comandaChapa && this.comandaChapa.PesProducte) pes = this.comandaChapa.PesProducte;
    else if(this.chapaSeleccionada && this.chapaSeleccionada.Pes) pes = this.chapaSeleccionada.Pes;
    this.comandaChapa.PesAlta = pes  * Number(newValue.target.value);
  }

  private borrarComanda(idCompra: number) {
    this.apiService.borrarComandaCompraChapa(idCompra).subscribe(() => {
      this.obtenerCompra();
      this.resetCompraChapaDetalle();
    });
  }

  public openDeleteDialog(id: number): void {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      data: { id: id, title: 'ELIMINAR COMANDA', msg: `¿Quiere eliminar esta comanda?`, cancelBtn: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.borrarComanda(id);
    });
  }

  public anadirComandaChapa() {
    if (!this.chapaSeleccionada.IdProducte || !this.comandaChapa.NumPeces) return;
    if (this.comandaChapa.NumPeces < 0 || this.comandaChapa.PesAlta < 0) return;

    const estado = estadoMapper[this.comandaChapa.Estat];

    const comandaChapaNueva: ComandaChapaNueva = {
      IdCompra: this.idCompra,
      IdProducte: this.chapaSeleccionada.IdProducte,
      NumPeces: this.comandaChapa.NumPeces,
      PesAlta: this.comandaChapa.PesAlta,
      IdEstat: estado,
    };

    this.apiService.anadirComandaChapa(comandaChapaNueva).subscribe(() => {
      this.obtenerCompra();
      this.resetCompraChapaDetalle();
      this.crearComandaNueva = false;
    });

    console.log('COMPRA A INSERTAR >>>>>>>>', comandaChapaNueva);
  }
}

