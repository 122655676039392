import { DialogMantenimientoKitComponent } from "../dialogs/dialog-mantenimiento-kit/dialog-mantenimiento-kit.component";
import { DialogMantenimientoProdOpcComponent } from "../dialogs/dialog-mantenimiento-prod-opc/dialog-mantenimiento-prod-opc.component";
import { DialogMantenimientoProdComponent } from "../dialogs/dialog-mantenimiento-prod/dialog-mantenimiento-prod.component";
import { DialogMantenimientoSubTipoKitComponent } from "../dialogs/dialog-mantenimiento-sub-tipo-kit/dialog-mantenimiento-sub-tipo-kit.component";
import { DialogMantenimientoComponent } from "../dialogs/dialog-mantenimiento/dialog-mantenimiento.component";
import { DialogMantenimiento2Component } from "../dialogs/dialog-mantenimiento2/dialog-mantenimiento2.component";
import { DialogMantenimiento3Component } from "../dialogs/dialog-mantenimiento3/dialog-mantenimiento3.component";
import { DialogMantenimientoTipoKitComponent } from "../dialogs/dialog-mantenimientoTipoKit/dialog-mantenimientoTipoKit.component";
import { DialogPreciosProdComponent } from "../dialogs/dialog-mantenimiento-prod/dialog-precios-prod/dialog-precios-prod.component";
import { DialogMantenimiento4Component } from "../dialogs/dialog-mantenimiento4/dialog-mantenimiento4.component";
import { DialogMantenimientoStockProduccionComponent } from "../dialogs/dialog-mantenimiento-stock-produccion/dialog-mantenimiento-stock-produccion.component";
import { MatDialogConfig } from "@angular/material/dialog";



function openDialogMantenimiento1(dialog: any, title: String, msg: String, mantenimiento: String, parametros: String) {
    const dialogRef = dialog.open(DialogMantenimientoComponent, {
        data: { title: title, msg: msg, mantenimiento: mantenimiento, parametros: parametros }
    });
    return dialogRef;
}

function openDialogSeleccionAnio(dialog: any, title: String, msg: String, mantenimiento: String, parametros: String) {
    const dialogRef = dialog.open(DialogMantenimientoComponent, {
        data: { title: title, msg: msg, mantenimiento: mantenimiento, parametros: parametros }
    });
    return dialogRef;
}


function openDialogMantenimiento2(dialog: any, title: String, msgFill: String, msgPare: String, mantenimiento: String, cols: any[], params: number) {
    const dialogRef = dialog.open(DialogMantenimiento2Component, {
        data: { title: title, msgFill: msgFill, msgPare: msgPare, mantenimiento: mantenimiento, cols: cols, parametros: params }
    });
    return dialogRef;
}
function openDialogMantenimiento3(dialog: any, title: String, cols: string[], tipo: string) {
    const dialogRef = dialog.open(DialogMantenimiento3Component, {
        data: { title: title, cols: cols, tipo }
    });
    return dialogRef;
}

function openDialogMantenimientoStockProduccion(dialog: any) {
    const config = new MatDialogConfig();
    config.autoFocus = 'dialog';
    const dialogRef = dialog.open(DialogMantenimientoStockProduccionComponent, config);
    return dialogRef;
}

function openDialogMantenimiento4(dialog: any, title: String, cols: string[], tipo: string) {
    const dialogRef = dialog.open(DialogMantenimiento4Component, {
        data: { title: title, cols: cols, tipo }
    });
    return dialogRef;
}

function openDialogMantenimientoTipoKit(dialog: any, title: String, cols: string[]) {
    const dialogRef = dialog.open(DialogMantenimientoTipoKitComponent, {
        data: { title: title, cols: cols }
    });
    return dialogRef;
}
function openDialogMantenimientoProd(dialog: any, title: String, cols: string[]) {
    const dialogRef = dialog.open(DialogMantenimientoProdComponent, {
        data: { title: title, cols: cols }
    });
    return dialogRef;
}
function openDialogMantenimientoSubTipoKit(dialog: any, title: String, cols: string[]) {
    const dialogRef = dialog.open(DialogMantenimientoSubTipoKitComponent, {
        data: { title: title, cols: cols }
    });
    return dialogRef;
}

function openDialogMantenimientoProdOpc(dialog: any, title: String, cols: string[]) {
    const dialogRef = dialog.open(DialogMantenimientoProdOpcComponent, {
        data: { title: title, cols: cols }
    });
    return dialogRef;
}
function openDialogMantenimientoKit(dialog: any, title: String, cols: string[]) {
    const dialogRef = dialog.open(DialogMantenimientoKitComponent, {
        data: { title: title, cols: cols }
    });
    return dialogRef;
}
function openDialogPrecioProd(dialog: any, title: String, cols: string[], idProd: number) {
    const dialogRef = dialog.open(DialogPreciosProdComponent, {
        data: { title: title, cols: cols, idProd: idProd }
    });
    return dialogRef;
}

export function mantenimiento1CondicionesPago(dialog: any): void {
    var title = "Mantenimiento Condición Pago";
    var msg = "Añade una condicion de pago";
    var mantenimiento = "condicion";
    var parametros = "";
    return openDialogMantenimiento1(dialog, title, msg, mantenimiento, parametros);
}
export function mantenimiento1ValidezOferta(dialog: any): void {
    var title = "Mantenimiento Validez Oferta";
    var msg = "Añade una validez de oferta";
    var mantenimiento = "validez";
    var parametros = "";
    return openDialogMantenimiento1(dialog, title, msg, mantenimiento, parametros);
}

export function mantenimiento1FormaPago(dialog: any): void {
    var title = "Mantenimiento Forma Pago";
    var msg = "Añade una forma de pago";
    var mantenimiento = "forma de pago";
    var parametros = "";
    return openDialogMantenimiento1(dialog, title, msg, mantenimiento, parametros);
}
export function mantenimiento1Patologia(dialog: any): void {
    var title = "Mantenimiento Patología";
    var msg = "Añade una patología";
    var mantenimiento = "patologia";
    var parametros = "";

    return openDialogMantenimiento1(dialog, title, msg, mantenimiento, parametros);
}

export function mantenimiento1Idioma(dialog: any): void {
    var title = "Mantenimiento Idioma";
    var msg = "Añade un idioma";
    var mantenimiento = "idioma";
    var parametros = "";

    return openDialogMantenimiento1(dialog, title, msg, mantenimiento, parametros);
}

export function mantenimiento1ModoEnvio(dialog: any): void {
    var title = "Mantenimiento Modo Envio";
    var msg = "Añade un modo envio";
    var mantenimiento = "envio";
    var parametros = "";

    return openDialogMantenimiento1(dialog, title, msg, mantenimiento, parametros);
}
export function mantenimiento1Pais(dialog: any): void {
    var title = "Mantenimiento País";
    var msg = "Añade un país";
    var mantenimiento = "pais";
    var parametros = "";

    return openDialogMantenimiento1(dialog, title, msg, mantenimiento, parametros);
}
export function mantenimiento1TipoProducto(dialog: any): void {
    var title = "Mantenimiento Tipo Producto";
    var msg = "Añade un tipo de producto";
    var mantenimiento = "tipo Producto";
    var parametros = "";

    return openDialogMantenimiento1(dialog, title, msg, mantenimiento, parametros);
}

export function mantenimiento1TipoSoporte(dialog: any) {
    var title = "Mantenimiento Tipo Soporte";
    var msg = "Añade un tipo de soporte";
    var mantenimiento = "tipo Soporte";
    var parametros = "";

    return openDialogMantenimiento1(dialog, title, msg, mantenimiento, parametros);
}

export function mantenimiento1Transportista(dialog: any) {
    var title = "Mantenimiento Transportista";
    var msg = "Añade un transportista";
    var mantenimiento = "transportista";
    var parametros = "";

    return openDialogMantenimiento1(dialog, title, msg, mantenimiento, parametros);
}
export function mantenimiento1CuentaBancaria(dialog: any) {
    var title = "Mantenimiento Cuenta Bancaria";
    var msg = "Añade una cuenta bancaria";
    var mantenimiento = "cuentas";
    var parametros = "";

    return openDialogMantenimiento1(dialog, title, msg, mantenimiento, parametros);
}


export function mantenimiento2Ciudad(dialog: any) {
    var title = "Mantenimiento Ciudades";
    var msgFill = "Añade una ciudad";
    var msgPare = "Selecciona una provincia"
    var mantenimiento = "ciudad";
    var cols = ["Ciudad", "Provincia"];
    return openDialogMantenimiento2(dialog, title, msgFill, msgPare, mantenimiento, cols, 0)
}
export function mantenimiento2Provincia(dialog: any) {
    var title = "Mantenimiento Provincia";
    var msgFill = "Añade una provincia";
    var msgPare = "Selecciona un país"
    var mantenimiento = "provincia";
    var cols = ["Provincia", "Pais"];
    return openDialogMantenimiento2(dialog, title, msgFill, msgPare, mantenimiento, cols, 0)
}
export function mantenimiento2TipoProducto(dialog: any) {
    var title = "Mantenimiento Tipo Producto";
    var msgFill = "Añade un Tipo Producto";
    var mantenimiento = "tipo Producto";
    var cols = ["Producto", "Control Stock"];
    return openDialogMantenimiento2(dialog, title, msgFill, "", mantenimiento, cols, 0)
}

export function mantenimiento3Comercial(dialog: any) {
    var title = "Mantenimiento Comercial";
    var cols = ["Comercial", "% Materiales", "% Colocacion"];
    var tipo = "comercial";
    return openDialogMantenimiento3(dialog, title, cols, tipo);
}

export function mantenimiento4Proveedor(dialog: any) {
    var title = "Mantenimiento Proveedor";
    var cols = ["Proveedor", "Direccion", "Ciudad", "Telefono"];
    var tipo = "proveedor";
    return openDialogMantenimiento4(dialog, title, cols, tipo);
}

export function mantenimiento2TiposKit(dialog: any) {
    var title = "Mantenimiento Tipo de Kits";
    var cols = ["TipoKit", "MorteroDef", "ResinaDef", "VarillaDef", "MorteroAlasDef", "CementoPortlandDef", "CementoEspumanteDef"];
    return openDialogMantenimientoTipoKit(dialog, title, cols);
}

export function mantenimiento2Producto(dialog: any) {
    var title = "Mantenimiento de Productos No Kits y No Opcionales";
    var cols = ["descripcion", "longitud", "chapa", "peso", "tipo Producto", "seZinca", "seFabrica", "stock Minimo", "stock Actual", "Punzado y plegado", "Plegado", "Zincado", "Zincador",
        "Zincador al cliente", "dataIni", "precio"];
    return openDialogMantenimientoProd(dialog, title, cols);
}

export function mantenimiento2SubTiposKit(dialog: any) {
    var title = "Mantenimiento de Subtipos Kit";
    var cols = ["subtipoKit", "rendMortero", "rendMorteroAlas", "rendResina", "rendVarilla", "rendCementoPortland", "rendCementoEspumante", "tipoKit"];
    return openDialogMantenimientoSubTipoKit(dialog, title, cols);
}

export function mantenimiento2ProductoOpc(dialog: any) {
    var title = "Mantenimiento de Productos Opcionales";
    var cols = ["descripcion", "tipoProdOpc", "unidadMedida", "peso", "tipo Producto", "conversion", "stock Minimo", "stock Actual", "dataIni", "precio"];
    return openDialogMantenimientoProdOpc(dialog, title, cols);
}

export function mantenimiento2Kits(dialog: any) {
    var title = "Mantenimiento de Kits";
    var cols = ["descripcion", "longitudCentral", "longitudExtremo", "inerciaCentral", "inerciaExtremo", "wCentral", "wExtremo", "stock Minimo", "stock Actual", "dataIni", "precio", "tieneImagen"];
    return openDialogMantenimientoKit(dialog, title, cols);
}

export function preciosProd(dialog: any, prod: string, idProd: number) {
    var title = "Historial de precios del producto: " + prod;
    var cols = ["fechaInicio", "fechaFin", "precio"];

    return openDialogPrecioProd(dialog, title, cols, idProd);
}
export function mantenimiento3Stock(dialog: any) {
    var title = "Mantenimiento Stock En Produccion";
    var cols = ["Producto", "A comprar", "Zincado"];
    var tipo = "stock";
    return openDialogMantenimiento3(dialog, title, cols, tipo);
}

export function mantenimientoStockProduccion(dialog: any) {
    return openDialogMantenimientoStockProduccion(dialog);
}

export function mantenimiento3Delegacion(dialog: any) {
    var title = "Mantenimiento Delegaciones";
    var cols = ["Delegacion", "Direccion", "CodigoPostal", "Poblacion"];
    var tipo = "delegacion";
    return openDialogMantenimiento3(dialog, title, cols, tipo);
}

export function mantenimiento2Componente(dialog: any, id: number, kit: string, needExtraColumnStock: boolean = false) {
    var title = "Mantenimiento Componentes del kit " + kit;
    var cols = ["Componente", "Cantidad", id];
    if(needExtraColumnStock) cols.push("Stock");
    var msgFill = "Selecciona un componente";
    var msgPare = "Añade una cantidad "
    var mantenimiento = "components";
    return openDialogMantenimiento2(dialog, title, msgFill, msgPare, mantenimiento, cols, 0)
}

export function mantenimiento2Encargado(dialog: any, id: number, cliente: string) {
    var title = "Encargados de Obra de " + cliente;
    var cols = ["encargado", "telefono"];
    var msgFill = "Añade un encargado "
    var mantenimiento = "encargado Obra";
    return openDialogMantenimiento2(dialog, title, msgFill, "", mantenimiento, cols, id)
}

export function seleccionInformeVentas(dialog: any): void {
    var title = "Informe Material Vendido";
    var msg = "Selecionar año: ";
    var mantenimiento = "seleccion";
    var parametros = "anio";
    return openDialogSeleccionAnio(dialog, title, msg, mantenimiento, parametros);
}
