import { Component, ViewChild } from '@angular/core';
import { ComprasDeChapaComponent } from '../compras-de-chapa.component';
import { ApiService } from '../../../services/api.service';
import { MatPaginator } from '@angular/material/paginator';
import { estadoMapper } from 'src/app/constants/comprasChapa.constant';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { type CompraChapaNuevaFinal, type CompraChapaNueva } from 'src/app/types/comprasChapa';
import { DateToStringGuion } from 'src/app/services/dataFunction';
import { TituloService } from '../../../services/titulo.service';

@Component({
  selector: 'app-nueva-compra-chapa',
  standalone: false,
  templateUrl: './nueva-compra-chapa.component.html',
  styleUrl: './nueva-compra-chapa.component.css'
})
export class NuevaCompraChapaComponent {
  @ViewChild(ComprasDeChapaComponent) miComponente!: ComprasDeChapaComponent;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  public dataSource!: MatTableDataSource<any>;
  public chapas: any[] = [];
  public chapa: any = {};
  public hayChapaSeleccionada = false;
  public mapper = estadoMapper;

  public compraChapaNueva: CompraChapaNueva = {} as CompraChapaNueva;
  public fechaActual = DateToStringGuion(new Date(Date.now()));
  public fechaMinima: Date = new Date();

  displayedColumns: string[] = ['Chapa', 'Cantidad'];

  constructor(private apiService: ApiService, private router: Router, private tituloService: TituloService) {
    this.dataSource = new MatTableDataSource(this.chapas);
    this.tituloService.enviarTitulo();
  }


  ngOnInit() {
    this.obtenerChapas();
    this.compraChapaNueva.DataCompra = this.fechaActual;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  private obtenerChapas() {
    this.apiService.obtenerChapas().subscribe((data) => {
      this.chapas = data.response;
      this.chapas.forEach(chapa => {
        chapa['cantidad'] = 0;
        chapa['estado'] = this.mapper['Pendiente'];
      });
      this.dataSource.data = this.chapas;
      this.dataSource.paginator = this.paginator;

      console.log('LISTA DE CHAPAS', this.chapas);
    });
  }

  public anadirCompra() {
    if (!this.compraChapaNueva.Tipus || !this.compraChapaNueva.DataCompra) return;
    if (this.compraChapaNueva.Preu < 0) return;
    
    const chapasAComprar = this.chapas.filter(chapa => chapa.cantidad > 0);
    const compraFinal = {...this.compraChapaNueva, Comandas: chapasAComprar} as CompraChapaNuevaFinal;
      
    console.log('COMPRA A INSERTAR >>>>>>>>', compraFinal);

    this.apiService.anadirCompraChapa(compraFinal).subscribe(() => {
      this.resetCompra();
    });
  }

  public resetCompra() {
    this.compraChapaNueva = {} as CompraChapaNueva;
    this.hayChapaSeleccionada = false;
    this.router.navigate(['compras-de-chapa']);
  }

  public seleccionarUnaChapa(chapa: any) {
    this.chapa = { ...chapa };
    this.hayChapaSeleccionada = true;
    console.log('COMPRA SELECCIONADA >>>>>>>>', this.chapa);
  }

  public colorRow(IdRow: number, IdElemento: number): any {
    return {
      'row-highlighted': IdElemento == IdRow,
    };
  }

  public onCantidadChange(idChapa: any, nuevaCantidad: number) {
    const nuevoNumero = Number(nuevaCantidad);
    console.log('NUEVO NUMERO', nuevoNumero);
    if (nuevoNumero < 0) return;

    const indexToUpdate = this.chapas.findIndex(chapa => chapa.IdProducte === idChapa);
    this.chapas[indexToUpdate].cantidad = nuevoNumero;  
    if(this.chapas[indexToUpdate].pesInitial === undefined) this.chapas[indexToUpdate].pesInitial = this.chapas[indexToUpdate].Pes;
    this.chapas[indexToUpdate].Pes = nuevoNumero * this.chapas[indexToUpdate].pesInitial;  

    console.log('LISTA DE CHAPAS', this.chapas[indexToUpdate]);
  }

  public handleDisableGuardar(): boolean {
    const tipoVacio = !this.compraChapaNueva.Tipus || this.compraChapaNueva.Tipus.trim() === '';
    const fechaVacia = !this.compraChapaNueva.DataCompra;
    const precioNegativo = this.compraChapaNueva.Preu < 0;
    const ningunaCantidadMayorCero = !this.chapas.some(chapa => chapa.cantidad > 0);

    return tipoVacio || fechaVacia || ningunaCantidadMayorCero || precioNegativo;
  }

  public volverAComprasChapa() {
    this.resetCompra();
    this.router.navigate(['compras-de-chapa']);
  }

}
