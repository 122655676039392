<div *ngIf="idiomaOferta != 1" class="content">

    <h1 class="titulo">NOTAS ACLARATORIAS</h1>

    <p style="margin-top: -5px;" class="resaltado">
        MECANOVIGA, S.L. facturará las unidades que se hayan suministrado según las necesidades de la obra
        y de acuerdo con la dirección facultativa.</p>
    <p class="resaltado" style="margin-top: 3px;">LOS PRECIOS FACILITADOS INCLUYEN:</p>
    <ul>
        <li>Todas las piezas que forman el kit MECANOVIGA, tornilleria necesaria para la unión
            de los elementos principales, y sus respectivas cartelas de apoyo.
        </li>
        <li>Material necesario para su colocación (varilla, resina, mortero ...).</li>
        <li>Montaje de los perfiles MVH.</li>
        <li>Montaje de los perfiles MVV y el inyectado.</li>
    </ul>
    <p class="resaltado">
        LOS PRECIOS FACILITADOS NO INCLUYEN SALVO QUE SE ESPECIFIQUEN EN LA OFERTA:
    </p>
    <ul>
        <li>Trabajos complementarios de otros ramos.</li>
        <li>Trabajos de albañilería  como preparación del soporte, derribo de cielos rasos, pasos de tabiques, saneado de las vigas y remate final.</li>
        <li>Desvío de instalaciones.</li>
        <li>Adaptación de carpinterías.</li>
        <li>Andamiaje para altura de vigas superiores a 3 mts.</li>
    </ul>
    <p  class="resaltado">
        OPCION MVH
    </p>

    <p>
        El retacado manual entre la MVH y la vigueta existente <u>es obligatorio</u>. Esta partida está contemplada en la colocación de las
        vigas.
    </p>
    <p  class="resaltado">
        OPCION MVV
    </p>
    <p>
        En el caso de los perfiles MVV la inyección de mortero está incluido en la partida de colocación de kits. Podría ser necesario en
        función de las necesidades de la obra y siempre que lo crea conveniente la dirección facultativa el recatado manual entre la MECANOVIGA 
        y la vigueta existente o el entrevigado. El retacado no está incluido salvo que se especifique en la oferta.
    </p>
    <p  class="resaltado" style="font-size: 20px !important;">
        Piezas especiales:
        Si durante el montaje fueran necesarias piezas especiales no especificadas en el presupuesto, para adaptarse a las particularidades de la 
        obra, éstas se facturarán aparte.
        Condiciones para el inicio del montaje:
    </p>
    <ul>
        <li>Disponer de suministro eléctrico y de agua en el punto de montaje.</li>
        <li>Disponer de los medios de elevación necesarios para llevar las vigas al punto de montaje.</li>
        <li>Disponer en el punto de montaje del andamiaje necesario para alturas mayores de 3 mts.</li>
        <li>Las vigas a reforzar deben estar accesibles y libres de obstáculos.</li>
        <li>Los apoyos de las vigas deben estar preparados: repicado de yesos, pintura, etc. (macizado de la obra hueca o rebozado con mortero si es necesario).</li>
        <li>Deben estar dispuestas las medidas de seguridad necesarias.</li>
        <li>La zona de trabajo debe estar convenientemente protegida contra golpes, polvos, chispas y líquidos.</li>
        <li>Todos los trabajos para conseguir estas condiciones deben estar realizados previamente al montaje de cualquier viga y no están contemplados en el presente presupuesto.</li>
    </ul>
    <p  class="resaltado" style="font-size: 20px !important;">
        Seguridad
    </p>
    <p  class="resaltado">
        El cliente será el responsable de las medidas de protección y seguridad general de la obra. Las medidas de seguridad personal
        del equipo de montaje serán aportadas por MECANOVIGA.
    </p>
    <p>La validez de la oferta es de {{validezOferta}}.</p>
    <p  class="resaltado" style="font-size: 20px !important;">
        Forma de pago:
    </p>
    <p>
        {{condicionesPago}}
    </p>
    <p class="resaltado">
        CONFORME CLIENTE:
    </p>

    <p  class="resaltado" style="font-size: 20px !important; margin-top: -5px;">
        Firmado:
    </p>
    
</div>

<div *ngIf="idiomaOferta == 1" class="content">

    <h1 class="titulo">NOTES ACLARIDORES</h1>

    <p style="margin-top: -5px;" class="resaltado">
  MECANOVIGA, S.L. facturarà les unitats que s'hagin subministrat segons les necessitats de l'obra
        i d'acord amb la direcció facultativa.</p>
    <p class="resaltado" style="margin-top: 3px;">ELS PREUS FACILITATS INCLOUEN:</p>
    <ul>
        <li>Totes les peces que formen el kit MECANOVIGA i centrals pertinents, cargols necessaris per a la unió
            dels elements principals, i les respectives cartel·les de suport.
        </li>
        <li>Material necessari per a la seva col·locació (vareta, resina, morter...).</li>
        <li>Muntatge dels perfils MVH.</li>
 
        <li>Muntatge dels perfils MVV i l'injectat.</li>   </ul>
    <p class="resaltado">
 ELS PREUS FACILITATS NO INCLOUEN EXCEPTE QUE S'ESPECIFIQUI A L'OFERTA:   </p>
    <ul>
 
 <li>Treballs complementaris d'altres rams.</li>
        <li>Treballs de paleta com a preparació del suport, enderrocament de cels rasos, passos d'envans, sanejat de les bigues i remat final.</li>
        <li>Desviament d'instal·lacions.</li>
        <li>Adaptació de fusteries.</li>
        <li>Andamiatge per a alçada de bigues superiors a 3 mts.</li>   </ul>
    <p  class="resaltado">
        OPCIO MVH
    </p>
    <p>
 
 El retacat manual entre la MVH i la bigueta existent <u>és obligatori</u>. Aquesta partida està contemplada en la col·locació de les
        bigues.   </p>
    <p  class="resaltado">
        OPCIO MVV
    </p>
    <p>
 
 En el cas dels perfils MVV la injecció de morter està inclosa a la partida de col·locació de kits. Podria ser necessari en
        funció de les necessitats de l'obra i sempre que ho cregui convenient la direcció facultativa el recatatge manual entre la MECANOVIGA
        i la bigueta existent o l'entrebigat. El retacat no està inclòs tret que s'especifiqui a l'oferta.   </p>
    <p  class="resaltado" style="font-size: 20px !important;">
 
 Peces especials:
        Si durant el muntatge fossin necessàries peces especials no especificades al pressupost, per adaptar-se a les particularitats de l'obra, aquestes es facturaran a part.
        Condicions per a l'inici del muntatge:
    </p>
    <ul>
        <li>Disposar de subministrament elèctric i d'aigua al punt de muntatge.</li>
        <li>Disposar dels mitjans d'elevació necessaris per portar les bigues al punt de muntatge.</li>
        <li>Disposar al punt de muntatge de la bastida necessària per a alçades majors de 3 mts.</li>
        <li>Les bigues a reforçar han d'estar accessibles i lliures d'obstacles.</li>
        <li>Els suports de les bigues han d'estar preparats: repicat de guixos, pintura, etc. (massissat de l'obra buida o arrebossat amb morter si cal).</li>
        <li>Han d'estar disposades les mesures de seguretat necessàries.</li>
        <li>La zona de treball ha d'estar convenientment protegida contra cops, pólvores, espurnes i líquids.</li>
        <li>Tots els treballs per aconseguir aquestes condicions han d'estar realitzats prèviament al muntatge de qualsevol biga i no estan contemplats en aquest pressupost.</li>   </ul>
    <p  class="resaltado" style="font-size: 20px !important;">
        Seguretat
    </p>
    <p  class="resaltado">
 
 El client serà el responsable de les mesures de protecció i seguretat general de lobra. Les mesures de seguretat personal
 de l'equip de muntatge seran aportades per MECANOVIGA.
    </p>
    <p>La validesa de l'oferta és de {{validezOferta}}.</p>
    <p  class="resaltado" style="font-size: 20px !important;">
        Forma de pagament:
    </p>
    <p>
        {{condicionesPago}}
    </p>
    <p class="resaltado">
        CONFORME CLIENT:
    </p>
 
    <p  class="resaltado" style="font-size: 20px !important; margin-top: -5px;">
        Signat:
    </p>
 
 </div> 